<template>
  <div>
    <nav>
      <!-- NAVBAR-->
      <Navbar
        :navbar="navbar"
        @audit="audit"
        @import="import_"
        @help="help"
      ></Navbar>
    </nav>
    <main :class="{
        'ml-10': $vuetify.display.mdAndUp,
        'ml-n4': $vuetify.display.smAndDown,
        'me-10': $vuetify.display.mdAndUp,
        'me-n4': $vuetify.display.smAndDown,
      }">
      <v-alert
        style="line-height: 1.5"
        v-if="auditHelp"
        class="mt-6"
        type="info"
        border="start"
        variant="tonal"
        icon="mdi-tune"
        transition="slide-y-reverse-transition"
        closable
      >{{ $t("menu.auditHelp") }}</v-alert>

      <v-alert
        style="line-height: 1.5"
        v-if="importHelp"
        class="mt-6"
        type="info"
        border="start"
        variant="tonal"
        icon="upload"
        transition="slide-y-reverse-transition"
        closable
      >{{ $t("menu.importHelp") }}</v-alert>

      <v-container fluid>
        <v-card
          flat
          class="mx-auto mb-0"
          max-width="1000"
        >
          <!-- module = {{module}} -->
          <v-row>
            <v-col cols="10">
              <v-row class="mt-1">
                <h1 class="text-h5 titleH1">
                  {{ $t("menu." + this.module + " Menu") }}
                </h1>
                <v-btn
                  icon
                  variant="text"
                  color="primary"
                  class="mb-2"
                  @click="moduleHelp = !moduleHelp"
                >
                  <v-icon size="16px">mdi-help-circle-outline</v-icon>
                </v-btn>
              </v-row>
              <!-- $store.state.user.profile = {{ $store.state.user.profile }} -->
              <h2
                class="text-h6 titleH1 ml-0"
                v-if="$store.state.user.entity == 'supplier' && $store.state.user.profile == 'Supplier'"
              >
                {{ $t("common.Supplier" ) }}: {{userSupplierName}}
              </h2>
              <h2
                class="text-h7 ml-0 mb-2"
                v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Representative') "
              >
                {{ $t("common.Representative" ) }}: {{$store.state.user.userName}}
              </h2>
              <h2
                class="text-h7 ml-0 mb-2"
                v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Driver') "
              >
                {{ $t("common.Driver" ) }}: {{$store.state.user.userName}}
              </h2>
              <h2
                class="text-h7 ml-0 mb-2"
                v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Waiter') "
              >
                {{ $t("common.Waiter" ) }}: {{$store.state.user.userName}}
              </h2>
              <h2
                class="text-h7 ml-0 mb-2"
                v-if="($store.state.user.entity == 'customer') "
              >
                {{ $t("common.Customer" ) }}: {{$store.state.user.userCustomerName}}
              </h2>
            </v-col>

            <!-- <v-col
              cols="4"
              v-if="$store.state.user.profile != 'Deliver' && $store.state.user.profile != 'Self-Service'"
            >
              <v-img
                id="imgLogoSrc"
                ref="imgLogoSrc"
                name="imgLogoSrc"
                class="mt-2 mb-2"
                v-if="src"
                :src="src"
                max-width="110"
                max-height="110"
              ></v-img>
            </v-col> -->

            <v-col cols="2">
              <v-btn
                icon
                variant="text"
                color="primary"
                class="mt-2 me-4"
                @click="refreshMenu = true; mountMenu()"
              >
                <v-icon size="24px">mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="mt-n4 mb-n4"
          >
            <v-col cols="8"></v-col>
            <v-col
              cols="4"
              v-if="$store.state.user.profile != 'Deliver' && $store.state.user.profile != 'Self-Service'"
            >
              <v-img
                id="imgLogoSrc"
                ref="imgLogoSrc"
                name="imgLogoSrc"
                v-if="src"
                :src="src"
                max-width="110"
                max-height="110"
              ></v-img>
            </v-col>
          </v-row>

          <!-- <v-divider class="mt-2"></v-divider> -->
          <v-alert
            style="line-height: 1.5"
            v-model="moduleHelp"
            type="info"
            border="start"
            variant="tonal"
            class="mt-2"
            transition="slide-y-reverse-transition"
            closable
          >
            <div v-if="$store.state.user.entity != 'supplier' && $store.state.user.entity != 'customer'">
              {{ $t(this.module.toLowerCase() + ".moduleHelp") }}
            </div>
            <div v-if="$store.state.user.entity == 'supplier' && $store.state.user.profile == 'Supplier'">
              {{ $t("purchase.moduleSupplierHelp") }}
            </div>
            <div v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Representative')">
              {{ $t("commercial.moduleRepresentativeHelp") }}
            </div>
            <div v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Driver')">
              {{ $t("delivery.moduleDriverHelp") }}
            </div>
            <div v-if="($store.state.user.entity == 'supplier' && $store.state.user.profile == 'Waiter')">
              {{ $t("consumer.moduleConsumerHelp") }}
            </div>
            <div v-if="$store.state.user.entity == 'customer'">
              {{ $t("commercial.moduleCustomerHelp") }}
            </div>
          </v-alert>

          <!-- COMMAND -->
          <v-card
            flat
            class="mt-2 mb-4"
          >
            <v-row
              no-gutters
              v-if="module == 'Consumer' && $store.state.parameter.consumer_command && loadedLayoutConsumer"
            >
              <v-col cols="6">
                <v-text-field
                  color="primary"
                  variant="underlined"
                  :label="$t('command.Command')"
                  v-model="commandId"
                  ref="commandId"
                  id="commandId"
                  :hint="$t('command.hintCommandId')"
                  class="ml-4"
                  type="number"
                  imputmode="numeric"
                  maxlength="20"
                  min="0"
                  @keydown.enter.prevent="consultCommand(); "
                  :rules="[rules.commandIdRules()]"
                  validate-on="blur"
                  @click:clear="alertStatusCommand = ''"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <!--   -->
            <v-alert
              style="line-height: 1.5"
              v-if="alertStatusCommand && commandId"
              type="warning"
              border="start"
              variant="tonal"
              class="ml-4 me-4 mt-4 mb-4"
              transition="slide-y-reverse-transition"
              closable
            >
              {{  alertStatusCommand  }}
            </v-alert>
          </v-card>
          <!--  -->

          <div
            class="mb-4"
            v-if="loadingPage"
          >
            <LoadingPage />
          </div>
          <!-- {{ menuUser }} -->
          <v-row
            v-if="menuUser.length > 0"
            class="mb-4"
          >
            <v-col
              v-for="item in menuUser"
              :key="item.menuTitle"
              :cols="12"
            >
              <v-card
                v-if="item.view && item.displayLine"
                class="mt-4 elevation-3"
                variant="elevated"
              >
                <v-card
                  flat
                  v-if="item.menuColor == ''"
                  class="d-flex flex-no-wrap justify-space-between"
                >
                  <div>
                    <v-card-title
                      class="text-h5 titleH1"
                      @click="recent = true;goRouter(item.accessName, item.menuTitle);"
                    >
                      {{ $t("common." + item.menuTitle) }}
                    </v-card-title>
                    <v-divider class="mt-2"></v-divider>
                    <v-card-title class="text-h4 text-primary">
                      {{item.counter}}
                    </v-card-title>
                  </div>
                  <div>
                    <img
                      class="mt-4 mb-4 me-4"
                      :width="140"
                      :src="require(`@/assets/illustrations/` + item.menuSrc)"
                    />
                  </div>
                </v-card>

                <!-- -sparkline -->
                <v-card
                  v-else
                  flat
                  :color="item.menuColor"
                >
                  <!-- {{item.menuColor}} -->
                  <div v-if="item.accessName != 'Command' && item.accessName != 'Table'">
                    <v-card-text>
                      <v-sheet color="rgba(0, 0, 0, .15)">
                        <!-- <v-sheet> -->
                        <v-sparkline
                          class="me-4"
                          v-if="item.value.length > 0"
                          :model-value="item.value"
                          color="rgba(255, 255, 255, .7)"
                          height="90"
                          line-width="3"
                          padding="22"
                          stroke-linecap="round"
                          smooth
                        >
                          <template v-slot:label="item">
                            {{ formatValue(item.value) }}
                          </template>
                        </v-sparkline>
                        <!-- dateSum -->

                        <v-row
                          class="text-white text-center font-weight-thin"
                          style="opacity: 0.8"
                          v-if="item.value.length > 0"
                        >
                          <v-btn
                            v-bind="props"
                            variant="text"
                            icon
                            @click="showDateSum = !showDateSum"
                          >
                            <v-icon
                              class="ml-2 pa-0 ma-0"
                              size="14px"
                            >mdi-eye-outline</v-icon>
                          </v-btn>

                          <v-col
                            v-if="showDateSum"
                            v-for="dt in item.dateSum"
                            :key="item.dateSum"
                          >
                            <div :class="{'text-caption': $vuetify.display.smAndDown,
                                          'font-weight-thin': $vuetify.display.smAndDown,
                                          'ma-0': $vuetify.display.smAndDown,
                                          'pa-0': $vuetify.display.smAndDown,}">
                              {{ dt.substring(0,5) }}
                            </div>
                          </v-col>
                        </v-row>

                      </v-sheet>
                    </v-card-text>
                    <v-card-text class="text-white text-center mt-0 mb-0">
                      <div :class="{
                        'text-h5': $vuetify.display.mdAndUp,
                        'text-subtitle-1': $vuetify.display.smAndDown,
                        'ma-0': $vuetify.display.smAndDown,
                        'pa-0': $vuetify.display.smAndDown,
                      }">
                        {{ $t("common." + item.menuTitle) }} :
                        {{ formatDate(item.start) }} ~
                        {{ formatDate(item.finish) }}
                      </div>
                    </v-card-text>
                  </div>

                  <!-- COMMAND & TABLE -->
                  <div v-else>
                    <v-card-text>
                      <v-sheet color="rgba(0, 0, 0, .15)">
                        <v-sparkline
                          v-if="item.value.length > 0"
                          :model-value="item.value"
                          color="rgba(255, 255, 255, .7)"
                          height="90"
                          line-width="3"
                          padding="16"
                          stroke-linecap="round"
                          smooth
                        >
                          <template v-slot:label="item">
                            {{ item.value }}
                          </template>
                        </v-sparkline>
                      </v-sheet>
                    </v-card-text>
                    <!--  -->
                    <v-card-title
                      class="text-subtitle-1 text-white ml-2"
                      v-html="item.status"
                    > </v-card-title>
                  </div>
                </v-card>

                <v-divider v-if="item.menuColor == ''"></v-divider>
                <div v-if="item.accessName != 'Command' && item.accessName != 'Table'">
                  <v-card-actions>
                    <!-- New -->
                    <v-btn
                      v-if="item.new && item.accessName != 'CashFlow'"
                      icon
                      style="opacity: 0.8"
                      @click="action = 'new';goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <!-- CashFlow -->
                    <v-btn
                      v-if="item.accessName == 'CashFlow'"
                      small
                      plain
                      class="me-12"
                      style="opacity: 0.8"
                      @click="action = 'report';goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon
                        class="me-2"
                        size="24px"
                      >mdi-chart-areaspline</v-icon>
                      {{ $t("common.Cash Flow") }}
                    </v-btn>

                    <!-- Import -->
                    <v-btn
                      v-if="btnImportShow && item.new && item.configure && (item.accessName == 'Customer' || item.accessName == 'Supplier' || item.accessName == 'Employee' || item.accessName == 'Product' || item.accessName == 'Service')"
                      class="ml-2 me-2"
                      small
                      plain
                      color="primary"
                      @click="action = 'import';goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon
                        class="me-1"
                        size="16px"
                      >mdi-upload</v-icon>
                      <div v-if="$vuetify.display.mdAndUp">
                        <div v-if="item.accessName =='Customer'">
                          {{ $t("import.Import Customers") }}
                        </div>
                        <div v-if="item.accessName =='Supplier'">
                          {{ $t("import.Import Suppliers") }}
                        </div>
                        <div v-if="item.accessName =='Employee'">
                          {{ $t("import.Import Employees") }}
                        </div>
                        <div v-if="item.accessName =='Product'">
                          {{ $t("import.Import Products") }}
                        </div>
                        <div v-if="item.accessName =='Service'">
                          {{ $t("import.Import Services") }}
                        </div>
                      </div>
                      <div v-else>
                        {{ $t("import.Import") }}
                      </div>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <!-- Seller Commission -->
                    <v-btn
                      v-if="(item.accessName == 'Invoice' && $store.state.sellerCounter != 0 && module != 'Consumer')"
                      class="me-4"
                      small
                      plain
                      @click="action = 'report';goRouter(item.accessName + '_Seller', item.menuTitle);"
                    >
                      <v-icon size="24px">mdi-format-list-checkbox</v-icon>
                      {{ $t("common.Seller Commission") }}
                    </v-btn>

                    <!-- Consumer Manufacture -->
                    <v-btn
                      v-if="(item.accessName == 'Consumer' && $store.state.user.consumerManufacture && loadedLayoutConsumer)"
                      class="me-4"
                      small
                      plain
                      @click="action = 'report';goRouter(item.accessName + '_Manufacture', item.menuTitle); "
                    >
                      <v-icon class="me-1">mdi-format-list-checks</v-icon>
                      {{ $t("common.Manufacture") }}
                    </v-btn>

                    <!-- Audit -->
                    <v-btn
                      v-if="item.configure && iconAuditShow && item.accessName != 'CashFlow'"
                      icon
                      style="opacity: 0.8"
                      @click="goAudit(item.accessName)"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>

                    <!-- recent -->
                    <v-btn
                      v-if="item.accessName != 'CashFlow'"
                      icon
                      style="opacity: 0.8"
                      @click="recent = true;goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon>mdi-history</v-icon>
                    </v-btn>

                    <!-- select -->
                    <v-btn
                      v-if="item.accessName != 'CashFlow'"
                      icon
                      style="opacity: 0.8"
                      @click="recent = false; goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>

                    <!-- report for Conciliation -->
                    <v-btn
                      v-if="item.new && item.configure && item.accessName == 'CashFlow'"
                      small
                      plain
                      class="ml-4 me-4"
                      style="opacity: 0.8"
                      @click="action = 'report';goRouter('conciliation', item.menuTitle);"
                    >
                      <v-icon
                        class="me-2"
                        size="24px"
                      >mdi-bank-transfer-out</v-icon>
                      {{ $t("common.Bank Conciliation") }}
                    </v-btn>

                    <!-- report -->
                    <v-btn
                      v-if="item.print && item.accessName != 'Enterprise' &&  item.accessName != 'User' && item.accessName != 'CashFlow'"
                      icon
                      style="opacity: 0.8"
                      @click="action = 'report'; goRouter(item.accessName, item.menuTitle);"
                    >
                      <v-icon>mdi-list-box-outline</v-icon>
                    </v-btn>

                    <!-- help -->
                    <v-btn
                      v-if="                     
                      (item.accessName == 'Customer' || item.accessName == 'Supplier' || item.accessName == 'Employee' ||  item.accessName == 'Seller' || item.accessName == 'Team' ||  item.accessName == 'Enterprise' || item.accessName == 'User' || item.accessName == 'Deliver' || item.accessName == 'Sale' || item.accessName == 'Quote' || item.accessName == 'Invoice' || item.accessName == 'Devolution')
                    "
                      icon
                      style="opacity: 0.8"
                      @click="action = 'help'; goHelp(item.accessName, item.menuTitle);"
                    >
                      <v-icon>mdi-help-circle-outline</v-icon>
                    </v-btn>

                  </v-card-actions>

                </div>
                <div v-else>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- Command -->
                    <v-btn
                      v-if="item.accessName == 'Command' && $store.state.parameter.consumer_command"
                      class="me-1"
                      style="min-width: 120px"
                      small
                      plain
                      @click="goCommand()"
                    >
                      <v-icon
                        size="24px"
                        class="me-2"
                      >mdi-barcode-scan</v-icon>
                      {{ $t("common.Commands") }}
                    </v-btn>

                    <!-- Table -->
                    <v-btn
                      v-if="item.accessName == 'Table' && ($store.state.parameter.consumer_table || $store.state.parameter.self_table)"
                      class="me-7"
                      style="min-width: 120px"
                      small
                      plain
                      @click="goTable()"
                    >
                      <v-icon
                        size="24px"
                        class="me-2"
                      >mdi-table-furniture</v-icon>
                      {{ $t("common.Tables") }}
                    </v-btn>
                    <!-- @click="$router.push({name: 'Table',params: {}});" -->
                  </v-card-actions>

                </div>

                <div
                  v-if="loadingCard && accessNameClicked == item.accessName"
                  class="ml-1 mt-0 mb-1 me-5"
                >
                  <v-progress-linear
                    indeterminate
                    :color="item.menuColor || 'primary'"
                  ></v-progress-linear>
                </div>

              </v-card>
            </v-col>
          </v-row>

          <!-- snackbar -->
          <v-snackbar
            v-model="snackbar.show"
            location="top"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
          >{{ snackbar.text }}</v-snackbar>
        </v-card>
        <br />
      </v-container>
    </main>
  </div>
</template>
<script>
import Navbar from "@/app/2_common/components/page/Navbar.vue";

//
import {
  selectSettingPage,
  selectPreferPage,
  selectLayoutModel,
} from "@/helpers/helpersApi.js";
//
import { clearSessionStorage } from "@/helpers/helpersFunction.js";
import { helpLink } from "@/helpers/helpers.js";

import { api } from "@/services.js";

export default {
  name: "MenuModule",
  components: {
    Navbar,
  },

  data() {
    return {
      showDateSum: false,
      refreshMenu: false,
      // establishment: "",
      commandId: "",
      alertStatusCommand: "",
      loadedLayoutConsumer: false,
      //
      module: "",
      menuUser: [],
      accessNameClicked: "",
      userSupplierName: "",
      //
      src: null,
      //
      action: "",
      recent: null,
      //
      report: [],
      layout: [],
      // standard
      locale: "",
      currency: "",
      loadingPage: true,
      loadingCard: false,
      groupUser: "",
      // Help
      moduleHelp: false,
      auditHelp: false,
      importHelp: false,
      iconAuditShow: false,
      btnImportShow: false,
      goBackOk: false,

      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },
      rules: {
        commandIdRules: () => {
          // prevents ctrl v
          if (!this.commandId) return true;

          if (Number(this.commandId) < 0) {
            return this.$t("rules.Invalid Value");
          }
          if (this.commandId > 9999) {
            return this.$t("rules.Invalid Value");
          }
          if (this.alertStatusCommand) {
            return this.alertStatusCommand;
          }
          return true;
        },
      },
    };
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////
    formatValue(value) {
      if (!value) return null;
      if (this.module == "Delivery") return value;
      //
      return new Intl.NumberFormat(this.locale, {
        style: "currency",
        currency: this.currency,
        // minimumFractionDigits: 0,
      }).format(value);
    },

    //////////////////////
    formatDate(value) {
      if (!value) return "";
      return new Date(value).toLocaleString().substring(0, 10);
    },

    //////////////////////////////////////////////////////////////
    // localStorage   --> Layout, Report and Prefer of user
    // sessionStorage --> Setting
    // vuex	          --> Parameter
    //////////////////////////////////////////////////////////////
    goRouter(route, menuTitle) {
      this.accessNameClicked = route;
      this.loadingCard = true;
      // console.log("goRouter --> route = " + route);

      // this[route]();
      if (
        this.module == "Purchase" ||
        this.module == "Commercial" ||
        this.module == "Consumer" ||
        this.module == "Delivery" ||
        route == "Kit"
      ) {
        this.goMovement(route, menuTitle);
      } else {
        this.goEntity(route, menuTitle);
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// NAVBAR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    help() {
      return;
    },
    /////////
    audit() {
      this.iconAuditShow = !this.iconAuditShow;
      this.auditHelp = this.iconAuditShow;
      sessionStorage.iconAuditShow = this.iconAuditShow;
    },

    ///////////
    import_() {
      this.btnImportShow = !this.btnImportShow;
      this.importHelp = this.btnImportShow;
      sessionStorage.btnImportShow = this.btnImportShow;
    },

    //////////
    goBack() {
      this.snackbar.show = false;
      this.goBackOk = true;
      this.$router.push({ name: "login" });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// ENTITY //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // customer, supplier, seller, team, user, enterprise, product, service
    ////////////////////
    async goEntity(route, menuTitle) {
      let pageId = route.toLowerCase();
      // console.log("goEntity pageId = " + pageId);
      sessionStorage.mainPage = pageId;
      if (pageId == "seller") {
        // sessionStorage.sellerId =
      }
      //////////////////////////////////////////////////////////////
      if (this.action == "import") {
        //
        let access = this.$store.state.user.access.find(
          (obj) => obj.accessName === route //
        );
        // Setting
        if (!sessionStorage.getItem(pageId + "Setting")) {
          await selectSettingPage(pageId);
        }
        // Prefer
        if (!localStorage.getItem(this.groupUser + pageId + "Prefer")) {
          await selectPreferPage(this.groupUser, pageId);
        }
        // Prefer + Select
        if (!localStorage.getItem(this.groupUser + pageId + "SelectPrefer")) {
          await selectPreferPage(this.groupUser, pageId + "Select");
        }
        //
        // console.log("this.module = " + this.module);
        let name = "";
        if (this.module == "Company") {
          name = "ImportRecordsCompany";
        } else if (this.module == "Stock") {
          name = "ImportRecordsStock";
        }

        sessionStorage.pageId = pageId;
        //
        this.$router.push({
          name: name,
          // params: { pageId: pageId },
        });
        return;
      }

      sessionStorage.removeItem(pageId + "Temp");
      //////////////////////////////////////////////////////////////
      if (pageId != "user") {
        // Setting
        if (pageId == "payable" || pageId == "receivable") {
          if (!sessionStorage.getItem("accountSetting")) {
            // fixed account
            await selectSettingPage("account");
          }
          //
          if (pageId == "receivable") {
            this.moduleCommercial(pageId);
          } else if (pageId == "payable") {
            this.modulePurchase(pageId);
          }
        } else {
          if (!sessionStorage.getItem(pageId + "Setting")) {
            await selectSettingPage(pageId);
          }
        }
        // Prefer
        if (!localStorage.getItem(this.groupUser + pageId + "Prefer")) {
          await selectPreferPage(this.groupUser, pageId);
        }
        // Prefer + Select
        if (!localStorage.getItem(this.groupUser + pageId + "SelectPrefer")) {
          await selectPreferPage(this.groupUser, pageId + "Select");
        }
      }

      //////////////////////////////////////////////////////////////
      // ReportPrefer
      // console.log("this.action = " + this.action);
      if (this.action == "report") {
        if (pageId == "cashflow" || pageId == "conciliation") {
          this.moduleCommercial(pageId);
          this.modulePurchase(pageId);
        }

        // // prefer pageIdReport
        if (!localStorage.getItem(this.groupUser + pageId + "ReportPrefer")) {
          await selectPreferPage(this.groupUser, pageId + "Report");
        }

        // Report
        console.log("2 insertReport - pageId = " + pageId);
        if (!localStorage.getItem(this.groupUser + pageId + "Report")) {
          await this.selectReport(pageId);
          console.log("this.report = " + JSON.stringify(this.report));
          if (this.report.length == 0) {
            await this.insertReportFirst(pageId);
          }
          localStorage.setItem(
            this.groupUser + pageId + "Report",
            JSON.stringify(this.report)
          );
        }
      }

      if (pageId == "product") {
        await this.getReportOthers("batch");
        await this.getReportOthers("serial");
        await this.moduleConsumer("Kit"); // load Kit
      }
      //////////////////////////////////////////////////////////////
      if (this.action == "new") {
        //
        let access = this.$store.state.user.access.find(
          (obj) => obj.accessName === route //
        );
        //
        sessionStorage.menuCounter = access.counter;
        sessionStorage.sourcePage = "MenuModule";
        this.$router.push({
          name: pageId,
          // params: { counter: access.counter },
        });
      } else {
        if (this.action == "report") {
          sessionStorage.reportId = pageId;
          sessionStorage.menuTitle = menuTitle;
          //
          // console.log("pageId = " + pageId);
          let name = "reportStandard";
          if (pageId == "cashflow") name = "CashFlow";
          if (pageId == "conciliation") name = "Conciliation";
          //
          this.$router.push({
            name: name,
          });
        } else {
          sessionStorage.recent = this.recent;
          sessionStorage.routePageId = pageId;
          // console.log("pageId = " + pageId);
          sessionStorage.menuCounter = 99;
          sessionStorage.sourcePage = "MenuModule";

          this.$router.push({
            name: route + "Select",
            // params: {
            //   recent: this.recent,
            //   pageId: "Menu",
            //   menuTitle: menuTitle,
            // },
          });
        }
      }
    },

    // get template report for batch, ...
    async getReportOthers(pageId) {
      //
      let access = {};

      // console.log("pageId = " + pageId);

      // batch //////////////
      if (pageId == "batch") {
        // parameter
        if (!this.$store.state.parameter.product_stock_control_batch) return;
        // access
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 424 //  "Batch"
        );
        if (access.print == false) return;

        // serial ///////////////////
      } else if (pageId == "serial") {
        // parameter
        if (!this.$store.state.parameter.product_stock_control_serial) return;
        // access
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 422 //  "serial"
        );
        if (access.print == false) return;
      }

      // prefer pageIdReport
      // if (!localStorage.getItem(this.groupUser + pageId + "ReportPrefer")) {
      //   await selectPreferPage(this.groupUser, pageId + "Report");
      // }
      // Report
      console.log("3 insertReport - pageId = " + pageId);
      if (!localStorage.getItem(this.groupUser + pageId + "Report")) {
        await this.selectReport(pageId);
        console.log("this.report = " + JSON.stringify(this.report));
        if (this.report.length == 0) {
          await this.insertReportFirst(pageId);
        }
        localStorage.setItem(
          this.groupUser + pageId + "Report",
          JSON.stringify(this.report)
        );
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MOVEMENT ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // modules Purchase, Commercial, Delivery
    async goMovement(route, menuTitle) {
      let access = {};
      let pageId = route.toLowerCase();
      // console.log("goMovement --> pageId   " + pageId);

      if (pageId == "delivery") {
        pageId = menuTitle;
        sessionStorage.typeDelivery = menuTitle;
      }
      ///////////////////////////////////////////////////////////////////////
      if (
        this.$store.state.user.entity == "supplier" &&
        this.$store.state.user.profile == "Supplier" &&
        this.action != "report"
      ) {
        pageId = pageId.replace("quotation", "quotationSupplier");
      }
      ///////////////////////////////////////////////////////////////////////

      sessionStorage.removeItem(pageId + "Temp");

      let processEnd = await this.getTemplates(pageId);

      if (processEnd) {
        // Loads the templates from the purchase order / sale because from receive ; expdition it can branch to the purchase order for example
        if (this.module == "Purchase") {
          this.modulePurchase(pageId);
        } else if (this.module == "Commercial") {
          this.moduleCommercial(pageId);
        } else if (this.module == "Consumer") {
          this.moduleConsumer(pageId);
        } else if (this.module == "Delivery") {
          this.moduleDelivery(pageId, menuTitle);
        }
        //
        await this.getReportOthers("batch");
        //
        await this.getReportOthers("serial");
      }

      if (this.action == "new") {
        //
        access = this.$store.state.user.access.find(
          (obj) => obj.accessName === route && obj.module === this.module //
        );
        // console.log("access=", JSON.stringify(access));
        ////////// GO /////////////////////////////////////////////////////////
        sessionStorage.menuCounter = access.counter;
        sessionStorage.menuSum = access.value;
        sessionStorage.menuTitle = menuTitle;
        sessionStorage.sourcePage = "MenuModule";
        //
        this.$router.push({
          name: route.toLowerCase(),
          // params: {
          //   sum: access.value,
          //   counter: access.counter,
          //   menuTitle: menuTitle,
          // },
        });
      } else {
        if (this.action == "report") {
          let reportId = pageId;
          if (pageId == "delivery") reportId = menuTitle;
          //
          sessionStorage.reportId = pageId;
          sessionStorage.menuTitle = menuTitle;
          this.$router.push({
            name: "reportStandard",
            // params: {
            //   reportId: reportId,
            //   menuTitle: menuTitle,
            // },
          });
        } else {
          sessionStorage.recent = this.recent;
          sessionStorage.routePageId = pageId;
          // console.log("pageId = " + pageId);

          this.$router.push({
            name: route + "Select",
            // params: {
            //   recent: this.recent,
            //   pageId: "Menu",
            //   menuTitle: menuTitle,
            // },
          });
        }
      }
    },

    /////////////////////
    goAudit(accessName) {
      sessionStorage.entity = accessName;
      this.$router.push({
        name: "Audit",
      });
    },

    ///////////
    goTable() {
      this.$router.push({
        name: "Table",
      });
    },

    ///////////
    goCommand() {
      this.$router.push({
        name: "Command",
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MODULE  Purchase  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    modulePurchase(pageId) {
      // console.log("modulePurchase --> pageId = " + pageId);

      let access = {};
      //////////////////////////////////////////////////////////////////////////////////////////////////////
      if (
        pageId == "receive" ||
        pageId == "payable" ||
        pageId == "cashflow" ||
        pageId == "conciliation"
      ) {
        // order ///////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 540
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("order");
        }

        // for financial module
        if (
          pageId == "payable" ||
          pageId == "cashflow" ||
          pageId == "conciliation"
        ) {
          // receive /////////////////////////////////
          access = this.$store.state.user.access.find(
            (obj) => obj.accessId === 550
          );
          if (access.view == true || access.new == true) {
            this.getTemplates("receive");
          }
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (
        pageId == "order" ||
        pageId == "payable" ||
        pageId == "cashflow" ||
        pageId == "conciliation"
      ) {
        // receive /////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 550
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("receive");
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (pageId == "solicit") {
        // quotation ///////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 510
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("quotation");
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (pageId == "quotation") {
        // order ///////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 540
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("order");
        }
        // for quotation also quotationSupplier
        this.getTemplates("quotationSupplier");
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (pageId == "back") {
        // receive ///////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 550
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("receive");
        }
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MODULE  Commercial  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moduleCommercial(pageId) {
      // console.log("moduleCommercial --> pageId = " + pageId);
      let access = {};

      //////////////////////////////////////////////////////////////////////////////////////////////////////
      if (pageId == "invoice") {
        // sale   //////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 740
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("sale");
        }

        // Carrier Delivery ////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 910
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("Carrier Delivery");
        }

        // Own Delivery ////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 920
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("Own Delivery");
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (
        pageId == "sale" ||
        pageId == "receivable" ||
        pageId == "cashflow" ||
        pageId == "conciliation"
      ) {
        // invoice //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 750
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("invoice");
        }

        // sale   //////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 740
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("sale");
        }

        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (pageId == "quote") {
        // sale ////////////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 740
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("sale");
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
      } else if (pageId == "devolution") {
        // invoice //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 750
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("invoice");
        }
        //
        // report invoice_seller needs invoice and devolution
      } else if (pageId == "invoice_seller") {
        // invoice //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 750
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("invoice");
        }
        // devolution //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 760
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("devolution");
        }
      }

      //////// kit ///////////////////////////////////////////////////////////////////////////////////////
      if (this.$store.state.parameter.product_kit) {
        // kit //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 470 // Kit
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("kit");
        }
      }
    },

    moduleConsumer(pageId) {
      // console.log("moduleConsumer --> pageId = " + pageId);

      let access = {};

      // loads because report consumer_manufacture needs to divert to deliver, consumer, self
      //
      if (pageId == "deliver" || pageId == "consumer_manufacture") {
        // deliver //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 610
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("deliver");
        }
      }

      if (pageId == "consumer" || pageId == "consumer_manufacture") {
        // consumer //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 620
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("consumer");
        }
      }

      if (pageId == "self" || pageId == "consumer_manufacture") {
        // self //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 630
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("self");
        }
      }

      //////// kit ///////////////////////////////////////////////////////////////////////////////////////
      if (this.$store.state.parameter.product_kit) {
        // kit //////////////////////////////
        access = this.$store.state.user.access.find(
          (obj) => obj.accessId === 470 // Kit
        );
        if (access.view == true || access.new == true) {
          this.getTemplates("kit");
        }
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MODULE  Delivery  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    moduleDelivery(pageId, menuTitle) {
      let access = {};
      // console.log("menuTitle= " + menuTitle);
      //////////////////////////////////////////////////////////////////////////////////////////////////////
      if (pageId == "delivery") {
        if (menuTitle == "Carrier Delivery") {
          // Carrier Delivery ////////////////////////
          access = this.$store.state.user.access.find(
            (obj) => obj.accessId === 910
          );
          if (access.view == true || access.new == true) {
            this.getTemplates(menuTitle);
          }
          //////////////////////////////////////////////////////////////////////////////////////////////////////
        } else if (menuTitle == "Own Delivery") {
          // Own Delivery ////////////////////////////
          access = this.$store.state.user.access.find(
            (obj) => obj.accessId === 920
          );
          if (access.view == true || access.new == true) {
            this.getTemplates(menuTitle);
          }
        }
      }
    },

    async getTemplates(pageId) {
      // console.log("1 getTemplates --> pageId = " + pageId);

      //////////////////////////////////////////////////////////////
      if (pageId != "invoice_seller" && pageId != "consumer_manufacture") {
        // Setting
        if (!sessionStorage.getItem(pageId + "Setting")) {
          await selectSettingPage(pageId);
        }
        // Prefer
        if (!localStorage.getItem(this.groupUser + pageId + "Prefer")) {
          await selectPreferPage(this.groupUser, pageId);
        }

        // Prefer + Select
        if (!localStorage.getItem(this.groupUser + pageId + "SelectPrefer")) {
          await selectPreferPage(this.groupUser, pageId + "Select");
        }

        // layout -->
        if (!localStorage.getItem(this.groupUser + pageId + "TabLayout")) {
          await this.selectLayout(pageId);
          if (this.layout.length == 0) {
            // let sortBy = "itemId";
            // if (pageId == "kit") sortBy = "blockNumber, orderIt";
            // if (pageId == "deliver" || pageId == "self") sortBy = "itemCombo";

            let sortBy = [{ key: "itemId", order: "asc" }];
            if (pageId == "kit") {
              sortBy = [
                { key: "blockNumber", order: "asc" },
                { key: "orderIt", order: "asc" },
              ];
            }
            if (pageId == "deliver" || pageId == "self") {
              sortBy = [{ key: "itemCombo", order: "asc" }];
            }

            //
            await this.insertLayoutFirst(pageId, sortBy);
          }

          // console.log("1 this.layout = " + JSON.stringify(this.layout));

          localStorage.setItem(
            this.groupUser + pageId + "TabLayout",
            JSON.stringify(this.layout)
          );
          // Layout Model
          let prefer = JSON.parse(
            localStorage.getItem(this.groupUser + pageId + "Prefer")
          );
          // console.log("layoutModelId= " + prefer.layoutModelId);
          await selectLayoutModel(
            this.groupUser,
            pageId,
            prefer.layoutModelId,
            prefer.modelId,
            this.$store.state.viewProductSupplier,
            this.$t
          );
        }
      }
      //////////////////////////////////////////////////////////////
      if (this.action == "report" || pageId == "quotation") {
        //
        let pageIdReport = pageId;
        if (this.action != "report" && pageId == "quotation") {
          pageIdReport = "order"; // button report order in quotation
        }
        //
        // prefer reportStandard
        if (
          !localStorage.getItem(this.groupUser + pageIdReport + "ReportPrefer")
        ) {
          await selectPreferPage(this.groupUser, pageIdReport + "Report");
        }
        // Report
        console.log("1 insertReport - pageIdReport = " + pageIdReport);

        if (!localStorage.getItem(this.groupUser + pageIdReport + "Report")) {
          await this.selectReport(pageIdReport);
          console.log("this.report = " + JSON.stringify(this.report));
          if (this.report.length == 0) {
            await this.insertReportFirst(pageIdReport);
          }
          localStorage.setItem(
            this.groupUser + pageIdReport + "Report",
            JSON.stringify(this.report)
          );
        }
      }

      if (pageId == "invoice_seller" || pageId == "consumer_manufacture")
        return true;
      //
      ////////// Product  ///////////////////////////////////////////////////
      // setting product
      if (!sessionStorage.productSetting) {
        await selectSettingPage("product");
      }
      // prefer product
      if (!localStorage.getItem(this.groupUser + "productPrefer")) {
        await selectPreferPage(this.groupUser, "product");
      }

      ////////// Service  ///////////////////////////////////////////////////
      // setting service
      if (!sessionStorage.serviceSetting) {
        await selectSettingPage("service");
      }
      // prefer service
      if (!localStorage.getItem(this.groupUser + "servicePrefer")) {
        await selectPreferPage(this.groupUser, "service");
      }

      return true;
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    snackbarTimeout(timeout) {
      this.mainButton.btnSaveDisabled = false;
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.goBack();
      }, this.snackbar.timeout);
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////

    goHelp(accessName) {
      // console.log("accessName = " + accessName);
      accessName = accessName.replace("Quote", "Customer Quote");
      accessName = accessName.replace("Devolution", "Customer Devolution");

      let title = this.$t("access." + accessName);
      helpLink(this.$t, title, "MenuModule", "");
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  REPORT  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    selectReport(reportId) {
      return api
        .get(`/report/?reportId=${reportId}`)
        .then((response) => {
          this.report = response.data;
          // console.log("selectReport = " + JSON.stringify(response.data));
        })
        .catch((err) => {
          this.loadingCard = false;
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => selectReport - " + reportId;
          this.snackbar.show = true;
          //
        });
    },
    /////////////////////////
    insertReportFirst(reportId) {
      let sortBy = [];
      if (reportId == "consumer_manufacture") {
        sortBy = [{ key: "date_inclusion", order: "asc" }];
      }

      //
      let modelId = this.$t("common." + reportId) + " 01";
      var report = {
        reportId: reportId,
        modelId: modelId,
        sortBy: sortBy,
        insertReportFirst: true,
        enterpriseCounter: this.$store.state.enterpriseCounter,
      };

      //
      return api
        .post("/report/insert/", report)
        .then((response) => {
          // console.log("insertReportFirst = " + JSON.stringify(response.data));
          let reportModelId = response.data;
          //
          sessionStorage.reportId = reportId; //
          sessionStorage.reportModelId = reportModelId;
          //
          this.report.push({
            reportModelId: reportModelId,
            modelId: modelId,
            tabIndex: 0,
          });
        })
        .catch((err) => {
          this.loadingCard = false;
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => insertReportFirst";
          this.snackbar.show = true;
          //
        })
        .finally(() => {
          // this.mainButton.btnSaveDisabled = false;
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  LAYOUT  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    selectLayout(layoutId) {
      return api
        .get(`/layout/select/?layoutId=${layoutId}`)
        .then((response) => {
          this.layout = response.data;
          // console.log("selectLayout = " + JSON.stringify(response.data));
        })
        .catch((err) => {
          this.loadingCard = false;
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => selectLayout - " + layoutId;
          this.snackbar.show = true;
          //
        });
    },
    /////////////////////////
    insertLayoutFirst(layoutId, sortBy) {
      //
      let layoutAnalyze = null;
      let layoutClientLayout = null;
      let layoutRoadmap = null;
      if (
        this.$store.state.user.profile != "Representative" &&
        this.$store.state.user.entity != "customer" &&
        (layoutId == "quote" ||
          layoutId == "sale" ||
          layoutId == "invoice" ||
          layoutId == "consumer")
      ) {
        layoutAnalyze = "X " + this.$t("common.Analyze") + " 01";
      }
      //
      if (layoutId == "deliver") {
        layoutClientLayout = "X Client Layout";
      }
      if (layoutId == "self") {
        layoutClientLayout = "X Self Layout";
      }

      if (layoutId == "Carrier Delivery" || layoutId == "Own Delivery") {
        layoutRoadmap = "X " + this.$t("common.Roadmap") + " 01";
      }
      //

      let modelId = this.$t("common." + layoutId) + " 01";
      let layout = {
        layoutId: layoutId,
        modelId: modelId,
        sortBy: sortBy,
        layoutAnalyze: layoutAnalyze,
        layoutClientLayout: layoutClientLayout,
        layoutRoadmap: layoutRoadmap,
      };
      // console.log("layout = " + JSON.stringify(layout));

      //
      return api
        .post("/layout/insert/", layout)
        .then((response) => {
          // console.log("layoutModelId = " + JSON.stringify(response.data));
          let layoutModelId = response.data;
          //
          sessionStorage.layoutId = layoutId; //
          sessionStorage.layoutModelId = layoutModelId;
          //
          // Analyze 01
          if (!layoutAnalyze && !layoutClientLayout && !layoutRoadmap) {
            this.layout.push({
              layoutModelId: layoutModelId,
              modelId: modelId,
              tabIndex: 0,
            });
          } else {
            this.layout.push({
              layoutModelId: layoutModelId - 1, // first
              modelId: modelId,
              tabIndex: 0,
            });
            this.layout.push({
              layoutModelId: layoutModelId, // second
              modelId: layoutAnalyze || layoutClientLayout || layoutRoadmap,
              tabIndex: 1,
            });
          }
          //
        })
        .catch((err) => {
          this.loadingCard = false;
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => insertLayoutFirst";
          this.snackbar.show = true;
          //
        })
        .finally(() => {
          // this.mainButton.btnSaveDisabled = false;
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  COUNTER   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////
    countEntity(entity) {
      let session = "counter" + entity;
      if (!this.refreshMenu && sessionStorage.getItem(session)) {
        return sessionStorage.getItem(session);
      }
      //
      let url = "/" + entity.toLowerCase() + "/count";
      return api
        .get(url)
        .then((response) => {
          // console.log(entity, JSON.stringify(response.data));
          let counter = 0;
          if (entity == "Enterprise") {
            counter = Number(response.data.enterprise_counter);
          } else {
            counter = Number(response.data);
          }
          //
          sessionStorage.setItem(session, JSON.stringify(counter));

          // Seller
          if (entity == "Service") {
            this.$store.commit("UPDATE_SERVICE_COUNTER", counter);
          } else if (entity == "Seller") {
            this.$store.commit("UPDATE_SELLER_COUNTER", counter);
          }

          return counter;
          //
        })
        .catch((err) => {
          this.loadingPage = false;
          if (err.response.status == 401) {
            this.$router.push({ name: "login" });
          } else {
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = err + " => count" + entity;
            this.snackbar.show = true;
          }
          //
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  SUM   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////
    sumChart(entity, menuTitle) {
      // console.log("entity ", entity);
      // console.log("menuTitle ", menuTitle);
      let session = "sum" + entity;
      if (entity == "Delivery") session = menuTitle;
      //
      if (!this.refreshMenu && sessionStorage.getItem(session)) {
        return JSON.parse(sessionStorage.getItem(session));
      }
      //

      let url = "/" + entity.toLowerCase() + `/sum/?menuTitle=${menuTitle}`;
      //
      return api
        .get(url)
        .then((response) => {
          // console.log(entity, JSON.stringify(response.data));
          if (
            response.data.length > 0 &&
            this.module == "Purchase" &&
            response.data[1].supplier_name
          ) {
            this.userSupplierName = response.data[1].supplier_name;
          }
          sessionStorage.setItem(session, JSON.stringify(response.data));
          //
          return response.data;
          //
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "login" });
          } else {
            this.loadingPage = false;
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = err + " => sum" + entity;
            this.snackbar.show = true;
          }
          //
        });
    },

    ///////////////////
    async mountMenu() {
      this.loadingPage = true;
      //
      if (
        this.$store.state.user.profile == "Representative" ||
        this.$store.state.user.profile == "Driver" ||
        this.$store.state.user.profile == "Waiter"
      ) {
        this.menuUser = this.$store.state.user.access.filter(
          (access) =>
            access.menuTitle != "" &&
            access.view &&
            (access.menuColor || access.menuSrc)
        );
      } else {
        this.menuUser = this.$store.state.user.access.filter(
          (access) => access.module === this.module && access.menuTitle != ""
        );
      }
      let parameterOK = await this.checkParameter();
      // console.log("this.menuUser = " + JSON.stringify(this.menuUser));
      ///////////////////////////////////////////////////////
      if (parameterOK) {
        for (let i = 0; i < this.menuUser.length; i++) {
          // if (this.menuUser[i].counter == 0) {
          if (this.menuUser[i].menuColor != "") {
            if (this.menuUser[i].view && this.menuUser[i].displayLine) {
              let sum = await this.sumChart(
                this.menuUser[i].accessName,
                this.menuUser[i].menuTitle
              );
              // console.log("sum = " + JSON.stringify(sum));
              let dateSum = [];
              let value = [];
              let status =
                this.$t("common." + this.menuUser[i].menuTitle) +
                ": " +
                "&nbsp; &nbsp"; // Command & Table
              let finish = "";
              if (sum) {
                for (let j = 0; j < sum.length; j++) {
                  // date
                  dateSum.push(this.formatDate(sum[j].date));
                  // total
                  value.push(sum[j].total);
                  // status
                  if (
                    (this.menuUser[i].accessName == "Command" ||
                      this.menuUser[i].accessName == "Table") &&
                    sum[j].total
                  ) {
                    status +=
                      this.$t(
                        this.menuUser[i].accessName.toLowerCase() +
                          "." +
                          sum[j].status
                      ) +
                      " (" +
                      sum[j].total +
                      ") " +
                      +sum[j].percent +
                      "%" +
                      "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp";
                  }
                  //
                  // console.log("sum[i].date = " + sum[i].date);
                  if (j == 0) this.menuUser[i].start = sum[j].date;
                  finish = sum[j].date;
                }
              }
              this.menuUser[i].dateSum = dateSum;
              this.menuUser[i].value = value;
              this.menuUser[i].status = status;
              this.menuUser[i].finish = finish;
            }
          } else if (this.menuUser[i].view && this.menuUser[i].displayLine) {
            // console.log("conunt = " + this.menuUser[i].accessName);
            this.menuUser[i].accessName;
            this.menuUser[i].counter = await this.countEntity(
              this.menuUser[i].accessName
            );
            // }
          }
        }
      }
      this.loadingPage = false;

      // help show
      this.moduleHelp = true;
      for (let i = 0; i < this.menuUser.length; i++) {
        if (this.menuUser[i].counter > 0 || this.menuUser[i].value.length > 0) {
          this.moduleHelp = false;
        }
      }

      // console.log("numberingRange = " + this.$store.state.user.numberingRange);
      if (this.$store.state.user.numberingRange) return;
      // console.log("this.menuUser = " + JSON.stringify(this.menuUser));
      if (sessionStorage.btnImportShow == "true") {
        this.btnImportShow = true;
      } else {
        if (this.module == "Company") {
          let counterCustomer = sessionStorage.getItem("counterCustomer");
          // console.log("Customer =", counterCustomer);
          if (counterCustomer < 100) {
            this.btnImportShow = true;
          } else if (counterCustomer > 10000) {
            this.btnImportShow = false;
            this.navbar.menu.splice(1, 1); // remove import
          }
        } else if (this.module == "Stock") {
          let counterProduct = sessionStorage.getItem("counterProduct");
          // console.log("Product =", counterProduct);
          if (counterProduct < 100) {
            this.btnImportShow = true;
          } else if (counterProduct > 10000) {
            this.btnImportShow = false;
            this.navbar.menu.splice(1, 1); // remove import
          }
        }
      }
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  CHECK SALE ID  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    checkUserSelfSale(userSelf) {
      return api
        .get(`/consumer/checkUserSelfSale/?userSelf=${userSelf}`)
        .then((response) => {
          // console.log("1 response = " + JSON.stringify(response.data));
          if (!response.data) {
            return null;
          } else {
            return response.data; // saleId
          }
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => checkUserSelfSale";
          this.snackbar.show = true;
          //
        })
        .finally(() => {
          //
        });
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  CONSULT COMMAND  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    consultCommand() {
      //
      this.commandId = this.commandId.toString().padStart(3, "0");
      api
        .get(`/command/consult/?commandId=${this.commandId}`)
        .then((response) => {
          // console.log("response = " + JSON.stringify(response.data));
          if (!response.data) {
            this.alertStatusCommand = this.$t("command.alertNotFound");
          } else {
            let command = response.data;
            // inactive
            if (command.inactive) {
              this.alertStatusCommand = this.$t("command.alertInactive");
              // Occupied
            } else if (command.statusCommand == "Occupied") {
              sessionStorage.saleId = command.saleIdCurrent;
              this.$router.push({
                name: "consumerEdit",
                // params: { saleId: command.saleIdCurrent },
              });
              return;
              // Released
            } else if (
              command.statusCommand == "Released" &&
              !command.saleIdCurrent
            ) {
              sessionStorage.commandId = command.commandId;
              this.$router.push({
                name: "consumer",
                // params: { commandId: command.commandId },
              });
              return;
              // Lost , Closing
            } else if (
              command.statusCommand == "Lost" ||
              command.statusCommand == "Closing"
            ) {
              this.alertStatusCommand =
                this.$t("command.Command") +
                " " +
                command.commandId.toString().padStart(3, "0") +
                " - " +
                this.$t("command." + command.statusCommand);
            }
          }
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => consultCommand";
          this.snackbar.show = true;
          //
        })
        .finally(() => {
          //
        });
    },

    //////////////////
    checkParameter() {
      for (let i = 0; i < this.menuUser.length; i++) {
        // console.log("1 accessName= " + this.menuUser[i].accessName);

        // kit
        if (
          this.menuUser[i].accessName == "Kit" &&
          !this.$store.state.parameter.product_kit
        ) {
          this.menuUser[i].displayLine = false;
        }

        // Deliver
        if (
          this.menuUser[i].accessName == "Deliver" &&
          !this.$store.state.user.consumerDelivery
        ) {
          this.menuUser[i].displayLine = false;
        }
        // Consumer
        if (
          this.menuUser[i].accessName == "Consumer" &&
          !this.$store.state.user.consumerConsumer
        ) {
          this.menuUser[i].displayLine = false;
        }
        // Self
        if (
          this.menuUser[i].accessName == "Self" &&
          !this.$store.state.user.consumerSelf
        ) {
          this.menuUser[i].displayLine = false;
        }

        // Command
        if (
          this.menuUser[i].accessName == "Command" &&
          !this.$store.state.parameter.consumer_command
        ) {
          this.menuUser[i].displayLine = false;
        }

        // Table
        if (
          this.menuUser[i].accessName == "Table" &&
          !this.$store.state.parameter.consumer_table &&
          !this.$store.state.parameter.self_table
        ) {
          this.menuUser[i].displayLine = false;
        }

        //
      }
      //
      return true;
    },
    ///////////////
    checkAccess(access) {
      if (
        this.$store.state.user.entity == "supplier" ||
        this.$store.state.user.entity == "customer" ||
        this.module == "Consumer"
      ) {
      } else {
        this.navbar.menu.splice(0, 0, {
          text: "Audit",
          icon: "mdi-tune",
          action: "audit",
          route: "",
        });
        if (
          (this.module == "Company" || this.module == "Stock") &&
          !this.$store.state.user.numberingRange
        ) {
          this.navbar.menu.splice(1, 0, {
            text: "Import",
            icon: "mdi-upload-outline",
            action: "import",
            route: "",
          });
        }
      }
    },
  },
  /////////////////
  beforeUnmount() {
    // console.log("beforeUnmount -> showDateSum = " + this.showDateSum);
    localStorage.showDateSum = this.showDateSum;
  },

  /////////////////
  async created() {
    if (!this.$store.state.login) {
      this.$router.push({ name: "login" });
    }
    // this.module = this.$route.params.module;
    // if (!this.module) this.module = sessionStorage.module;
    // if (!this.module) this.$router.go(-1);
    // sessionStorage.module = this.module;
    if (sessionStorage.module == undefined || !sessionStorage.module) {
      this.$router.push({ name: "menu" });
    }
    this.module = sessionStorage.module || "";
    // console.log("this.module =", JSON.stringify(this.module));

    this.locale = sessionStorage.locale;
    this.currency = this.$store.state.user.locale.currency;
    this.groupUser = this.$store.state.user.groupUser;
    // console.log("groupUser=", JSON.stringify(this.groupUser));

    if (sessionStorage.iconAuditShow == "true") this.iconAuditShow = true;
    //
    if (localStorage.showDateSum == "true") this.showDateSum = true;
    // console.log("this.showDateSum =", this.showDateSum);

    //
    this.checkAccess(null);
    //
    this.$store.commit("UPDATE_ACCESS_VIEW", false);
    this.$store.commit("UPDATE_ACCESS_EDIT", false);
    this.$store.commit("UPDATE_UNIT_MEASURE_SUPPLIER", "");
    this.$store.commit("UPDATE_UNIT_MEASURE_CUSTOMER", "");

    sessionStorage.commandId = "";

    // consumerLayout
    if (localStorage.getItem(this.groupUser + "consumerLayout")) {
      this.loadedLayoutConsumer = true;
    }

    // logo src /////////////////////////////////////////////////
    if (this.$store.state.user.src) {
      this.src = this.$store.state.user.src;
    }

    if (
      this.$store.state.user.profile != "Self-Service" &&
      this.$store.state.user.profile != "Deliver"
    ) {
      this.mountMenu();
      ///////////////////////////////////////
    } else {
      if (this.$store.state.user.profile == "Self-Service") {
        // Prefer
        let pageId = "self";
        if (!localStorage.getItem(this.groupUser + pageId + "Prefer")) {
          await selectPreferPage(this.groupUser, pageId);
        }
        //
        let saleId = null;
        if (this.$store.state.parameter.self_multi_order) {
          saleId = await this.checkUserSelfSale(this.$store.state.user.userId);
          // console.log("2 saleId =", saleId);
        }
        if (saleId) {
          sessionStorage.saleId = saleId;
          this.$router.push({
            name: "selfEdit",
            // params: { saleId: saleId },
          });
          return;
        } else {
          this.$router.push({
            name: "self",
          });
        }
      } else if (this.$store.state.user.profile == "Deliver") {
        // Prefer
        let pageId = "deliver";
        if (!localStorage.getItem(this.groupUser + pageId + "Prefer")) {
          await selectPreferPage(this.groupUser, pageId);
        }
        //
        this.$router.push({
          name: "deliver",
        });
      }
    }

    ////////////////////////////////////////////////////////////
  },
  /////////
  mounted() {
    clearSessionStorage();
  },
};
</script>

