// front-end call  
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable' // using npm
// import myFont from "@/assets/fonts/Roboto-Regular-normal.js";
import Roboto from "@/assets/fonts/Roboto-Regular-normal.js";
import RobotoBold from "@/assets/fonts/Roboto-Bold-normal.js";





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  PDF  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function createPDF(title, entityId, dataPDF, enterprise, layout, imprint, headerAutoTable, logoBase64, locale, currency, printPDF, sequence, rowTotal, t, accounts) {
  // pdfName
  let pdfName = title.replace(/ /g, "_") + "_" + ("0000" + entityId).slice(-4);
  pdfName = pdfName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // remove accents
  // console.log("dataPDF =" + JSON.stringify(dataPDF));
  // console.log("imprint =" + JSON.stringify(imprint));
  // console.log("headerAutoTable =" + JSON.stringify(headerAutoTable));

  // rows AutoTable
  var rows = dataPDF.itemPDF; // itens sortBy
  rows.push(rowTotal);
  // console.log("1 rows  =" + JSON.stringify(rows));
  // filter sequence
  // console.log("sequence =" + sequence);
  if (sequence >= 0) {
    rows = rows.filter((obj) => {
      return Number(obj.sequence) == Number(sequence);
    });
  }

  // A4     = 210   x 297 mm
  // Letter = 215,9 × 279,4 mm
  // Coil   = 80 mm x 40mts
  var coil = false;

  let paperSize = layout.paperSize;

  // console.log("1 paperSize   = " + paperSize);
  if (paperSize.substring(0, 4) == "Coil") { // Coil 80 mm
    coil = true;
    paperSize = paperSize.substring(5, 8).trim() + "x";
  } else if (paperSize.substring(0, 6) == "Custom") { // Custom
    paperSize = paperSize.substring(7);
  };
  //
  let factor = 1; // proportional factor for font size reduction
  let fontSize = 10;
  let margin = 10;
  let rowHeight = 6;
  let yHeader = 50; // y coordinate used for printing the header
  if (sequence >= 0) yHeader = 5;
  //
  if (paperSize != "A4" && paperSize != "Letter") {
    let split = paperSize.split("x");
    var width = split[0];
    var height = split[1];
    //
    if (coil) {
      factor = .7;
      fontSize = 7
      rowHeight = 4
      margin = 4
    } else {
      factor = ((width * 100) / 210) / 100
      if (factor > 1.5) factor = 1.5;
      fontSize = Math.round(fontSize * factor)
      rowHeight = Math.round(fontSize * factor)
      if (rowHeight < 2) rowHeight = 2;
      margin = Math.round(fontSize * factor)
    }
    yHeader = Math.round(yHeader * factor)
    //
    if (!height || height == 0) {
      // yHeader +
      height = Math.round(5 + (imprint.length * 2.2 * rowHeight) + (rows.length * 1.9 * rowHeight))
      if (imprint.some(function (o) { return o["value"] === "summary"; })) height += 15;
      if (imprint.some(function (o) { return o["value"] === "observation"; })) height += 10;
      if (height < width) height = width;
    }
    paperSize = [height, width];
    // console.log("height   = " + height);
  }
  // console.log("factor   = " + factor);
  // console.log("fontSize = " + fontSize);
  // console.log("rowHeight = " + rowHeight);
  // console.log("margin    = " + margin);
  // console.log("paperSize   = " + paperSize);
  // console.log("orientation = " + layout.orientation);
  // console.log("imprint.length = " + imprint.length);
  //

  // Default export is portrait, a4 paper, using millimeters for units & true = compress image
  const doc = new jsPDF(layout.orientation, 'mm', paperSize, true);
  // console.log("doc.internal =" + JSON.stringify(doc.internal.pageSize));

  let y = 0; // y coordinate
  let yGrid = 0; // y coordinate after printing the grid
  let yMultipleLines = 0; // y coordinate used for printing multi-line text
  //
  // console.log("doc.getFontList() =" + JSON.stringify(doc.getFontList()));
  doc.setFont("Roboto-Regular");
  doc.setFontSize(fontSize);

  // imprint
  let label = "";
  let column = "";
  let content = "";

  doc.setLineWidth(0.1); // line thickness

  // ///////////////////////////////////////
  for (let i = 0; i < imprint.length; ++i) {
    // align = imprint[i].align;
    column = imprint[i].value;


    column = column.replace(
      "dateIssue",
      "dateIssueLocale"
    );
    column = column.replace(
      "dateClosing",
      "dateClosingLocale"
    );

    //
    if (!imprint[i].grid && column !== "summary") {
      // label ///////////////////////////////
      if (imprint[i].title) {
        label = imprint[i].title;
      } else {
        label = imprint[i].text;
      }
      // content /////////////////////////////
      content = " " + dataPDF[column]; // dataPDF[column]
      content = content.trim().toString().replace("null", "");
      content = content.trim().toString().replace("undefined", "");

      //
      if (yGrid == 0) {
        y = yHeader + yMultipleLines + (imprint[i].order * rowHeight);
      } else {
        yGrid = yGrid + yMultipleLines + rowHeight;
        y = yGrid;
      };
      yMultipleLines = 0;
      //
      // console.log("y column = " + y + " " + column);
      if (column.match(/Id/) && !column.match(/addressDeliveryId/)) {
        // if (column == "kitId" || column == "solictId" || column == "orderId" || column == "receiveId" || column == "backId" || column == "quoteId" || column == "saleId" || column == "invoiceId" || column == "devolutionId" || column == "deliveryId" || column == "supplierName" || column == "customerName") {
        // doc.setFontType("bold");
        doc.setFont("Roboto-Bold");
      } else {
        doc.setFont("Roboto-Regular");
      };

      // console.log("y column = " + y + " " + column);

      if (column == "customerName" && content == "" && dataPDF.federalDocument) {
        content = dataPDF.federalDocument;
      };

      //
      if (sequence >= 0 && column == "dateIssueLocale") {
        let now = new Date();
        content = now.toLocaleString().substring(0, 17)
      };

      // print text
      doc.text(label, margin, y);
      let verticalBar = 49;
      if (coil) verticalBar = 40;
      //
      doc.line(verticalBar * factor, y - (4 * factor), verticalBar * factor, y + (2 * factor)); // line --> 4 + 2 = rowHeight

      // address
      if (column.match(/address/)) {
        // address
        if (dataPDF.addressDelivery.address) {
          content = dataPDF.addressDelivery.address + ", " + dataPDF.addressDelivery.number + " - " + dataPDF.addressDelivery.neighborhood
          doc.text(content, (verticalBar + 2) * factor, y);
          y = y + rowHeight;
          yMultipleLines = yMultipleLines + 6;
          content = dataPDF.addressDelivery.city + " - " + dataPDF.addressDelivery.state + " - " + dataPDF.addressDelivery.postalCode
          doc.text(content, (verticalBar + 2) * factor, y);
        };
      } else {
        if (column.match(/status/) && (!column.match(/date/)) && dataPDF[column]) {
          content = t("common." + dataPDF[column]);
        };
        if (column == "deliveryType" && dataPDF[column]) {
          content = t("common." + dataPDF[column]);
        };
        if (column.match(/Value/) && dataPDF[column]) {
          content = formatValue(dataPDF[column], locale, currency);
        };
        if (column == "accountId" && dataPDF[column]) {
          content = getAccountCode(dataPDF[column], accounts)
        };


        //
        doc.text(content, (verticalBar + 2) * factor, y);
      };

      // observation -> provides y-coordinate for multi-line text
      if (column.match(/observation/) || column.match(/Comment/)) {
        // var splitText = doc.splitTextToSize(content, doc.internal.pageSize.width); // prever texto grande sem quebra de linha
        var splitText = doc.splitTextToSize(content);
        if (splitText.length > 1) {
          yMultipleLines = splitText.length * (3.5 * factor)
          // console.log("yMultipleLines " + yMultipleLines);
        };
      };
    };

    // summary ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if (column == "summary") {
      // console.log("dataPDF.summary " + JSON.stringify(dataPDF.summary));
      if (yGrid == 0) {
        y = y + yMultipleLines + (11 * factor);
      } else {
        y = yGrid + (11 * factor);
      };
      //
      doc.line(margin, y, doc.internal.pageSize.width - margin, y)
      // y = y + 4;
      y = y + rowHeight;

      doc.setFont("Roboto-Bold");
      doc.text(dataPDF.summary.summaryLocale, margin, y);

      for (let z = 0; z <= 1; z++) {
        // y = y + 5;
        y = y + rowHeight;

        let x = 35 * factor;
        let iText = ""
        for (let i = 0; i <= 4; i++) {
          if (z == 0 && (i == 0 || i == 4)) {
            doc.setFont("Roboto-Bold");
          } else {
            doc.setFont("Roboto-Regular");
          }
          //
          iText = dataPDF.summary[z].slice(i, i + 1)
          // console.log("iText " + i + " - " + iText);
          iText = iText.toString().replace("null", " ");
          iText = iText.toString().replace("undefined", " ");
          iText = iText.toString().replace("NaN", " ");
          doc.text('' + iText, x, y);
          x = x + (30 * factor)
        };
      };
      // y = y + 4;
      y = y + rowHeight

      doc.line(margin, y, doc.internal.pageSize.width - margin, y)
    };

    // AUTOTABLE ////////////////////////////////////////////////////////////////////////////////////////
    if (imprint[i].grid) {
      if (y == 0) y = yHeader;
      // textBeforeGrid /////////////////////////////////////////
      if (layout.textBeforeGrid) {
        var splitText = doc.splitTextToSize(layout.textBeforeGrid);
        if (splitText.length > 1) {
          yMultipleLines = splitText.length * 3.5
        };
        doc.text(splitText, margin, y + (11 * factor));
        // y = y + 5
        y = y + rowHeight
      }
      let factorCell = factor;
      if (factorCell < .5) factorCell = .5; // factor for cellWidth
      // print grid ////////////////////////////////////////////////
      // columns
      const columns = headerAutoTable;

      // options ///////////////////////////////////////////////////
      // var options = {}; // 
      ///////////////////////////////////////////////////////
      // doc.autoTable(columns, rows, options);
      let cellPadding = { top: 2, right: 2, bottom: 2, left: 2 };
      if (coil) {
        cellPadding = { top: 1, right: 1, bottom: 1, left: 0 }
      };
      autoTable(doc, {
        // options ///////////////////////////////////////////////////
        theme: 'plain', // grid plain
        startY: y + (11 * factor) + yMultipleLines,
        margin: margin,
        // columnWidth: "wrap",
        // styles: { overflow: "linebreak", overflowColumns: false },
        rowPageBreak: "avoid",
        //
        styles: {
          fontSize: fontSize,
          cellPadding: cellPadding,
        },
        headStyles: {
          fontStyle: 'Roboto-Regular',
          fillColor: [236, 239, 241], // blue-grey-lighten-5
          // textColor: [255, 255, 255], // white
          fontSize: fontSize
        },
        //
        columnStyles: {
          description: {
            fontSize: fontSize,
          },
          itemId: {
            halign: "center"
          },
          productId: {
            halign: "center"
          },
          serviceId: {
            halign: "center"
          },
          quantityIt: {
            halign: "right",
            cellWidth: factorCell * 14,
          },
          priceCost: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          totalCost: {
            halign: "right",
            cellWidth: factorCell * 24,
          },
          priceSale: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          totalSale: {
            halign: "right",
            cellWidth: factorCell * 24,
          },
          deliveryDays: {
            halign: "center"
          },
          priceCostLast: {
            halign: "right",
            cellWidth: factorCell * 18,
          },
          averageCostSum: {
            halign: "right",
            cellWidth: factorCell * 18,
          },
          itemCommissionValue: {
            halign: "right",
            cellWidth: factorCell * 18,
          },
          margin: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          marginAverageCost: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          // kit
          currentIt: {
            halign: "right",
            cellWidth: factorCell * 17,
          },
          priceCostIt: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          totalCostIt: {
            halign: "right",
            cellWidth: factorCell * 24,
          },
          averageCostIt: {
            halign: "right",
            cellWidth: factorCell * 22,
          },
          totalAverageCostIt: {
            halign: "right",
            cellWidth: factorCell * 24,
          },
          // Delivery
          totalQuantity: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          totalSale: {
            halign: "right",
            cellWidth: factorCell * 24,
          },
          weightLiquidIt: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          weightGrossIt: {
            halign: "right",
            cellWidth: factorCell * 22,
          },
          volumeIt: {
            halign: "right",
            cellWidth: factorCell * 22,
          },

          weightLiquid: {
            halign: "right",
            cellWidth: factorCell * 21,
          },
          weightGross: {
            halign: "right",
            cellWidth: factorCell * 22,
          },
          volume: {
            halign: "right",
            cellWidth: factorCell * 22,
          },

          itemTeamValue: {
            halign: "right",
            cellWidth: factorCell * 18,
          },

          orderDelivery: {
            halign: "center",
            cellWidth: factorCell * 18,
          },
          timeDelivered: {
            halign: "center",
            cellWidth: factorCell * 18,
          },
          // distance
          distance: {
            halign: "right",
          },
          // duration
          duration: {
            halign: "right",
          },

          // Delivery
          quantityDelivered: {
            halign: "right",
            cellWidth: factorCell * 21,
          },


        },
        /////////////
        didParseCell: function (data) {
          if (data.section === 'body') {
            if ((data.column.dataKey == 'priceCost' || data.column.dataKey == 'totalCostIt' || data.column.dataKey == 'priceCostIt' || data.column.dataKey == 'totalCost' || data.column.dataKey == 'priceSale' || data.column.dataKey == 'totalSale' || data.column.dataKey == 'itemCommissionValue' || data.column.dataKey == 'priceCostLast' || data.column.dataKey == 'averageCostSum' || data.column.dataKey == 'margin' || data.column.dataKey == 'marginAverageCost' || data.column.dataKey == 'averageCostIt' || data.column.dataKey == 'totalAverageCostIt') && data.cell.raw) {
              data.cell.text = formatValue(data.cell.raw, locale, currency);
            };
            //
            if (!data.cell.raw) data.cell.text = "";
            // if (data.column.dataKey == 'productId' || data.column.dataKey == 'serviceId') {
            // if (!data.cell.raw) data.cell.text = "";
            // };
            if (data.column.dataKey.includes('date') && data.column.dataKey.includes('Locale') && data.cell.raw) {
              data.cell.text = formatDate(data.cell.raw, locale);
            };

            if (!data.cell.raw) data.cell.text = "";
            //
            if (data.column.dataKey == 'description') {
            };
          };
          // fontStyle = 'bold'
          if (data.row.index === data.table.body.length - 1 && !sequence) {
            data.cell.styles.fontStyle = 'bold';
          };
          //
          doc.line(5 * factor, data.cell.y, doc.internal.pageSize.width - (5 * factor), data.cell.y)
        },
        /////////////
        didDrawCell: function (data) {
          // srcX
          if (data.column.dataKey == 'srcX' && data.cell.raw && data.cell.section === 'body') {
            let base64 = dataPDF.itemPDF[data.row.index].srcBase64;
            if (base64) {
              doc.addImage(base64, "JPEG", data.cell.x, data.cell.y, (data.cell.height * .6), (data.cell.height * .6));
            };
          };
          // Line
          if (data.column.index == 0 && data.cell.section === 'body') {
            doc.setLineWidth(0.1)
            doc.setDrawColor(207, 216, 220); // blue-grey-lighten-4
            doc.line(margin, data.cell.y, doc.internal.pageSize.width - margin, data.cell.y)
          };
        },
        // body ////////////
        body: rows,
        // columns ////////
        columns: columns,
      })

      yGrid = parseInt(doc.previousAutoTable.finalY) + 5; // provides for page break
      // console.log("yGrid " + yGrid);
      //
      // textAfterGrid ///////////////////////////////////////////
      if (layout.textAfterGrid) {
        var splitText = doc.splitTextToSize(layout.textAfterGrid);
        if (splitText.length > 1) {
          yMultipleLines = splitText.length * 3.5
        };
        doc.text(splitText, margin, yGrid);
      }
    }
  }
  if (!sequence) {
    // HEADER & FOOTER /////////////////////////////////////////////
    // Get the number of pages
    const pageTotal = doc.internal.getNumberOfPages();
    // doc.setFontSize(fontSize);
    for (var i = 1; i <= pageTotal; i++) {
      //
      doc.setPage(i); // Go to page i
      doc.setLineWidth(0.3); // line thickness
      //////////////////////////////////////////////////////////////////
      // HEADER ///////////////////////////////////////////////////////
      // logo url 
      // console.log("(sessionStorage.logoImgRatio = " + sessionStorage.logoImgRatio);
      let xHeader = 150 * factor;
      if (coil) {
        xHeader = 50;
      };
      let width = sessionStorage.logoImgRatio * 30 * factor;
      if (logoBase64) {
        doc.addImage(logoBase64, "JPEG", 10, 10, width, 30 * factor);
      };
      doc.setDrawColor(25, 118, 310); // primary -> 1976D2 - blue-darken-2
      doc.line(5 * factor, 40 * factor, doc.internal.pageSize.width - (5 * factor), 40 * factor)

      // console.log("enterprise.contact.phones[1] = " + enterprise.contact.phones[1]);
      // phone
      doc.text(enterprise.contact.phones[0].phone, xHeader, 15 * factor);
      // website
      if (enterprise.company.website) {
        doc.setTextColor(25, 118, 310); // primary
        doc.text(enterprise.company.website, xHeader, 20 * factor);
        doc.setTextColor(100);
      }
      // address
      let address = enterprise.address.address + ", " + enterprise.address.number + " - " + enterprise.address.neighborhood
      doc.text(address, xHeader - 20, 30 * factor);
      address = enterprise.address.city + " - " + enterprise.address.state + " - " + enterprise.address.postalCode
      doc.text(address, xHeader - 20, 35 * factor);

      ////////////////////////////////////////////////////////////////
      // FOOTER///////// /////////////////////////////////////////////
      // line
      doc.setLineWidth(0.2);
      doc.text("blue21.com.br", 5 * factor, doc.internal.pageSize.height - 7 * factor); //key is the interal pageSize function
      //
      doc.setDrawColor(69, 90, 100); // blue-grey-darken-2
      doc.line(5 * factor, doc.internal.pageSize.height - rowHeight, doc.internal.pageSize.width - (21 * factor), doc.internal.pageSize.height - rowHeight)
      // pag
      var str = "Pag: " + String(i) + " / " + pageTotal;
      doc.text(str, doc.internal.pageSize.width - (21 * factor), doc.internal.pageSize.height - rowHeight); //key is the interal pageSize function
    };
  };
  //
  try {
    rows.pop()
    // doc.page = 1;
    if (!printPDF) {
      doc.save(pdfName + ".pdf");
      // print
    } else {
      doc.setProperties({ title: pdfName });
      window.open(doc.output('bloburl'), '_blank');
    };
    //

  } catch (e) {
    rows.pop()
    console.info("createPDF Err " + e);
  } finally {
    //
  }
};

///////////////////////////////////////////////
function formatValue(value, locale, currency) {
  if (!value) return null;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

///////////////////////////////////
function formatDate(date, locale) {
  // console.log("date = " + date);
  if (!date) return null;
  return new Date(date).toLocaleDateString(locale);
};

function formatDateSplit(value, locale) {
  if (!value) return "";
  // console.log("locale = " + locale);

  if (locale.includes("pt")) {
    return value.split("-").reverse().join("/");
  } else {
    return value;
  }
};


/////////////////////////////////////////
function formatValueConciliation(value, locale) {
  if (!value) return null;
  //
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  }).format(value);
};




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  MODULE PURCHASE /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  TOTAL GRID /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function calculatePurchaseGrid(item) {
  // console.log("calculateTotal -> item =" + JSON.stringify(item));

  let rowTotal = { // keep fields like a card: {
    itemId: null,
    productId: null,
    serviceId: null,
    referenceId: "",
    description: "",
    quantityIt: 0,
    measureSu: "",
    priceCost: null,
    priceCostIso: null,
    totalCost: 0,
    //
    dateDelivery: null,
    dateDeliveryLocale: null,
    orderIt: null,
    //
    update: false,
    exclude: false,
    dateUpdateIt: null,
  };
  let quantityTotal = 0;
  let totalCost = 0;
  //
  if (item.length > 0) {
    for (let i = 0; i < item.length; i++) {
      // console.log("[i]            = " + i);
      // console.log("[i].quantityIt = " + item[i].quantityIt);
      if (!item[i].exclude) {
        quantityTotal =
          quantityTotal + Number(item[i].quantityIt);
        totalCost =
          totalCost + (Number(item[i].quantityIt) * Number(item[i].priceCost));
      };
      // console.log("quantityTotal = " + quantityTotal);
    }
    // rowTotal
    rowTotal.quantityIt = quantityTotal;
    rowTotal.totalCost = totalCost;
  }
  return rowTotal;
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  TOTAL PURCHASE  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function calculatePurchaseTotal(db, locale, currency, parameter_prorate_freight, parameter_prorate_expenses) {
  // console.log("db.discountPercent = " + db.discountPercent);
  // console.log("db.discountValue = " + db.discountValue);

  if (db.discountPercent && !db.discountValue) {
    db.discountValue =
      db.rowTotal.totalCost * (db.discountPercent / 100);
  } else {
    if (db.discountValue && !db.discountPercent) {
      db.discountPercent =
        (db.discountValue / db.rowTotal.totalCost) * 100;
    }
  }
  db.totalExpenses = Number(db.freightValue) +
    Number(db.otherExpensesValue) +
    Number(db.insuranceValue)
  //
  db.totalPurchaseValue = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(
    db.rowTotal.totalCost +
    Number(db.freightValue) +
    Number(db.otherExpensesValue) +
    Number(db.insuranceValue) -
    Number(db.discountValue)
  );
  //
  calculateTotalWeightAndVolume(db, locale)
  //
  if (sessionStorage.pageId == "receive" || sessionStorage.pageId == "back") {
    prorateExpensesPurchase(db, parameter_prorate_freight, parameter_prorate_expenses);
  };

  // dateIssue & totalPurchase => used in layout --> e-mail
  sessionStorage.dateIssue = db.dateIssueLocale || db.dateIssue;
  sessionStorage.totalValue = db.totalPurchaseValue;

};

///////////////////////////////////////////
function calculateTotalWeightAndVolume(db, locale) {
  db.weightIn = "";
  db.totalWeightLiquid = null;
  db.totalWeightGross = null;
  //
  db.dimensionIn = "";
  db.totalVolume = null;
  //
  if (db.item.length > 0) {
    for (let i = 0; i < db.item.length; i++) {
      // console.log("[i].quantityIt = " + db.item[i].quantityIt);
      if (db.item[i].weightIn) {
        db.weightIn = db.item[i].weightIn;
        db.totalWeightLiquid = db.totalWeightLiquid + (db.item[i].weightLiquid * db.item[i].quantityIt);
        db.totalWeightGross = db.totalWeightGross + (db.item[i].weightGross * db.item[i].quantityIt);
      };
      // console.log("db.totalVolume i = " + i + ' ' + db.totalVolume);
      if (db.item[i].dimensionIn) {
        db.dimensionIn = db.item[i].dimensionIn + "³";
        db.totalVolume = db.totalVolume + (db.item[i].width * db.item[i].height * db.item[i].depth * db.item[i].quantityIt);
      };
    };
  }
};


///////////////////////////////////////////
function prorateExpensesPurchase(db, parameter_prorate_freight, parameter_prorate_expenses) {
  let weighTotal = db.totalWeightGross || db.totalWeightLiquid;
  // console.log("parameter_prorate_freight = " + parameter_prorate_freight);
  //
  if (db.item.length > 0) {
    for (let i = 0; i < db.item.length; i++) {
      let weigh = db.item[i].weightGross || db.item[i].weightLiquid;
      let volume = db.item[i].width * db.item[i].height * db.item[i].depth;

      /// FREIGHT /////////////////////////////
      db.item[i].freightItem = 0;
      if (Number(db.freightValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_freight == 1) { // Value
          db.item[i].freightItem = (db.item[i].totalCost / db.rowTotal.totalCost) * db.freightValue;
          ////  Weight 
        } else if (parameter_prorate_freight == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].freightItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.freightValue;
          };
          //// Volume
        } else if (parameter_prorate_freight == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].freightItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.freightValue;
          };
        };
        // console.log("db.item[i].freightItem = " + i + ' ' + db.item[i].freightItem);
        if (!db.item[i].freightItem) db.item[i].freightItem = 0;
      };

      /// INSURANCE /////////////////////////////
      db.item[i].insuranceItem = 0;
      if (Number(db.insuranceValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_expenses == 1) { // Value
          db.item[i].insuranceItem = (db.item[i].totalCost / db.rowTotal.totalCost) * db.insuranceValue;
          ////  Weight 
        } else if (parameter_prorate_expenses == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].insuranceItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.insuranceValue;
          };
          //// Volume
        } else if (parameter_prorate_expenses == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].insuranceItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.insuranceValue;
          };
        };
        // console.log("db.item[i].insuranceItem = " + i + ' ' + db.item[i].insuranceItem);
        if (!db.item[i].insuranceItem) db.item[i].insuranceItem = 0;

      };


      /// OTHER EXPENSES /////////////////////////////
      db.item[i].otherExpensesItem = 0;
      if (Number(db.otherExpensesValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_expenses == 1) { // Value
          db.item[i].otherExpensesItem = (db.item[i].totalCost / db.rowTotal.totalCost) * db.otherExpensesValue;
          ////  Weight 
        } else if (parameter_prorate_expenses == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].otherExpensesItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.otherExpensesValue;
          };
          //// Volume
        } else if (parameter_prorate_expenses == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].otherExpensesItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.otherExpensesValue;
          };
        };
        // console.log("db.item[i].otherExpensesItem = " + i + ' ' + db.item[i].otherExpensesItem);
        if (!db.item[i].otherExpensesItem) db.item[i].otherExpensesItem = 0;

      };
    };
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  MODULE Commercial /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  TOTAL GRID /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function calculateCommercialGrid(item) {
  // console.log("calculateTotal -> item =" + JSON.stringify(item));

  let rowTotal = { // keep fields like a card: {
    itemId: null,
    productId: null,
    serviceId: null,
    referenceId: "",
    description: "",
    quantityIt: 0,
    measureCl: "",
    priceSale: null,
    priceSaleIso: null,
    totalSale: 0,
    //
    deliveryDays: null,
    dateDelivery: null,
    dateDeliveryLocale: null,
    orderIt: null,
    //
    itemCommissionValue: null,
    priceCostLast: null,
    averageCostSum: null,
    margin: null,
    marginAverageCost: null,
    //
    update: false,
    exclude: false,
    dateUpdateIt: null,
  };
  //
  let quantityTotal = 0;
  let totalSale = 0;
  let totalCommission = 0;
  //
  if (item.length > 0) {
    for (let i = 0; i < item.length; i++) {
      // console.log("[i]            = " + i);
      // console.log("[i].quantityIt = " + item[i].quantityIt);
      if (!item[i].exclude) {
        quantityTotal =
          quantityTotal + Number(item[i].quantityIt);
        totalSale =
          totalSale + (Number(item[i].quantityIt) * Number(item[i].priceSale));
        totalCommission =
          totalCommission + Number(item[i].itemCommissionValue);
      };
      // console.log("quantityTotal = " + quantityTotal);
    }
    // rowTotal
    rowTotal.quantityIt = quantityTotal;
    rowTotal.totalSale = totalSale;
    rowTotal.itemCommissionValue = totalCommission;
  }
  return rowTotal;
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  TOTAL Commercial  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function calculateCommercialTotal(db, locale, currency) {
  // console.log("db.discountPercent = " + db.discountPercent);
  // console.log("db.discountValue = " + db.discountValue);
  // console.log("db.serviceChargeValue = " + db.serviceChargeValue);
  if (!db.serviceChargeValue) db.serviceChargeValue = 0;

  if (db.discountPercent && !db.discountValue) {
    db.discountValue =
      db.rowTotal.totalSale * (db.discountPercent / 100);
  } else {
    if (db.discountValue && !db.discountPercent) {
      db.discountPercent =
        (db.discountValue / db.rowTotal.totalSale) * 100;
    }
  }
  calculateServiceCharge(db, locale);
  //
  db.totalExpenses = Number(db.freightValue) +
    Number(db.otherExpensesValue) +
    Number(db.insuranceValue) + db.serviceChargeValue;
  //
  db.totalCommercialValue = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(
    db.rowTotal.totalSale +
    Number(db.freightValue) +
    Number(db.otherExpensesValue) +
    Number(db.insuranceValue) -
    Number(db.discountValue) +
    Number(db.serviceChargeValue)
  );
  //
  calculateTotalWeightAndVolume(db, locale)

  //
  // if (sessionStorage.pageId == "invoice" || sessionStorage.pageId == "devolution") {
  let parameter_prorate_freight = 1  // Value
  let parameter_prorate_expenses = 1  // Value
  //
  prorateExpensesCommercial(db, parameter_prorate_freight, parameter_prorate_expenses);
  // };

  calculateMargin(db);

  mainItemBrand(db);
  // dateIssue & totalCommercial => used in layout --> e-mail
  sessionStorage.dateIssue = db.dateIssueLocale || db.dateIssue;
  sessionStorage.totalValue = db.totalCommercialValue;

};

function calculateServiceCharge(db, locale) {
  // serviceCharge ////////////////////////////////////////
  if (db.serviceChargePercent && !db.serviceChargeValue) {
    db.serviceChargeValue =
      db.rowTotal.totalSale * (db.serviceChargePercent / 100);
  } else {
    if (db.serviceChargeValue && !db.serviceChargePercent) {
      db.serviceChargePercent =
        (db.serviceChargeValue / db.rowTotal.totalSale) * 100;
    };
  };

  // console.log("db.parameterServiceChargePercent = " + db.parameterServiceChargePercent);
  // console.log("db.serviceChargePercent          = " + db.serviceChargePercent);
  // console.log("db.teamPercent                   = " + db.teamPercent);

  // waiterPercent --> proportional calculation
  let factor = 1;
  if (db.teamPercent) {
    db.waiterPercent = db.teamPercent;
    if (db.parameterServiceChargePercent && db.parameterServiceChargePercent != db.serviceChargePercent && db.serviceChargePercent > 0 && db.serviceChargePercent < db.teamPercent) {
      factor = (db.serviceChargePercent * 100) / db.parameterServiceChargePercent / 100
      // console.log("factor            = " + factor);
      db.waiterPercent = db.teamPercent * factor
    };
  };
  // console.log("1 db.waiterPercent              = " + db.waiterPercent);

  // waiterValue
  if (db.serviceChargeValue && db.serviceChargeValue > 0 && db.waiterPercent > 0) {
    db.serviceChargeValue = parseFloat(db.serviceChargeValue).toFixed(2);
    db.waiterValue =
      db.rowTotal.totalSale * (db.waiterPercent / 100);
    //
    if (db.waiterValue > db.serviceChargeValue) {
      db.waiterValue = db.serviceChargeValue;
      db.waiterPercent = db.serviceChargePercent;
    };
  };
  // console.log("2 db.waiterValue               = " + db.waiterValue);
  // console.log("2 db.waiterPercent             = " + db.waiterPercent);

};




//////////////////////////////
function calculateMargin(db) {
  let totalMargin = 0;
  let totalMarginAverageCost = 0;
  //
  if (db.item.length > 0) {
    for (let i = 0; i < db.item.length; i++) {
      if (!db.item[i].exclude) {
        if (db.item[i].itemId) {
          if (db.item[i].averageCostSum == 0) db.item[i].averageCostSum = db.item[i].priceCostLast;
          //
          db.item[i].margin = (Number(db.item[i].totalSale) - (Number(db.item[i].totalSale) * db.discountPercent / 100)) - (Number(db.item[i].priceCostLast) * Number(db.item[i].quantityIt)) - Number(db.item[i].freightItem) - Number(db.item[i].insuranceItem) - Number(db.item[i].otherExpensesItem) - Number(db.item[i].itemCommissionValue)
          if (db.item[i].serviceId > 0) {
            db.item[i].marginAverageCost = db.item[i].margin
          } else {
            db.item[i].marginAverageCost = (Number(db.item[i].totalSale) - (Number(db.item[i].totalSale) * db.discountPercent / 100)) - (Number(db.item[i].averageCostSum) * Number(db.item[i].quantityIt)) - Number(db.item[i].freightItem) - Number(db.item[i].insuranceItem) - Number(db.item[i].otherExpensesItem) - Number(db.item[i].itemCommissionValue)
          }
          //
          // console.log("db.item[i].priceCostLast    = " + db.item[i].priceCostLast);
          // console.log("db.item[i].averageCostSum    = " + db.item[i].averageCostSum);
          // console.log("db.item[i].freightItem       = " + db.item[i].freightItem);
          // console.log("db.item[i].margin            = " + db.item[i].margin);
          // console.log("db.item[i].marginAverageCost = " + db.item[i].marginAverageCost);
          //
          totalMargin =
            totalMargin + Number(db.item[i].margin);
          totalMarginAverageCost =
            totalMarginAverageCost + Number(db.item[i].marginAverageCost);

        } else {
          // rowTotal
          // db.item[i].margin = totalMargin;
          // db.item[i].marginAverageCost = totalMarginAverageCost;
        }
        // rowTotal
        db.rowTotal.margin = totalMargin;
        db.rowTotal.marginAverageCost = totalMarginAverageCost;
        // console.log(" 0 db.rowTotal.margin = " + db.rowTotal.margin);
      };
    }
  };
};

///////////////////////////////////
export function mainItemBrand(db) {
  // // mainItem
  for (let i = 0; i < db.item.length; ++i) {
    // console.log("db.l.layout.sortBy = " + db.l.layout.sortBy);

    // if (db.l.layout.sortBy.substring(0, 6) == "itemId" || db.l.layout.sortBy.substring(0, 9) == "itemCombo") {
    if (!db.l.layout.sortBy || JSON.stringify(db.l.layout.sortBy) == "[]" || db.l.layout.sortBy[0].key == "itemId" || db.l.layout.sortBy[0].key == "itemCombo") {
      if (i == 0) {
        db.item[i].mainItem = true;
      } else {
        if (db.item[i].itemCombo != db.item[i - 1].itemCombo) {
          db.item[i].mainItem = true;
        } else {
          db.item[i].mainItem = false;
        }
      }
    } else {
      db.item[i].mainItem = null;
    }
  }
};


///////////////////////////////////////////
function prorateExpensesCommercial(db, parameter_prorate_freight, parameter_prorate_expenses) {
  let weighTotal = db.totalWeightGross || db.totalWeightLiquid;
  // console.log("parameter_prorate_freight = " + parameter_prorate_freight);
  //
  if (db.item.length > 0) {
    for (let i = 0; i < db.item.length; i++) {
      let weigh = db.item[i].weightGross || db.item[i].weightLiquid;
      let volume = db.item[i].width * db.item[i].height * db.item[i].depth;

      /// FREIGHT /////////////////////////////
      db.item[i].freightItem = 0;
      if (Number(db.freightValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_freight == 1) { // Value
          db.item[i].freightItem = (db.item[i].totalSale / db.rowTotal.totalSale) * db.freightValue;
          ////  Weight 
        } else if (parameter_prorate_freight == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].freightItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.freightValue;
          };
          //// Volume
        } else if (parameter_prorate_freight == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].freightItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.freightValue;
          };
        };
        // console.log("db.item[i].freightItem = " + i + ' ' + db.item[i].freightItem);
        if (!db.item[i].freightItem) db.item[i].freightItem = 0;
      };

      /// INSURANCE /////////////////////////////
      db.item[i].insuranceItem = 0;
      if (Number(db.insuranceValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_expenses == 1) { // Value
          db.item[i].insuranceItem = (db.item[i].totalSale / db.rowTotal.totalSale) * db.insuranceValue;
          ////  Weight 
        } else if (parameter_prorate_expenses == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].insuranceItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.insuranceValue;
          };
          //// Volume
        } else if (parameter_prorate_expenses == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].insuranceItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.insuranceValue;
          };
        };
        // console.log("db.item[i].insuranceItem = " + i + ' ' + db.item[i].insuranceItem);
        if (!db.item[i].insuranceItem) db.item[i].insuranceItem = 0;

      };


      /// OTHER EXPENSES /////////////////////////////
      db.item[i].otherExpensesItem = 0;
      if (Number(db.otherExpensesValue && db.item[i].itemId) > 0) {
        //// Value 
        if (parameter_prorate_expenses == 1) { // Value
          db.item[i].otherExpensesItem = (db.item[i].totalSale / db.rowTotal.totalSale) * db.otherExpensesValue;
          ////  Weight 
        } else if (parameter_prorate_expenses == 2) { //  Weight
          if (db.item[i].weightIn) {
            db.item[i].otherExpensesItem = ((weigh * db.item[i].quantityIt) / weighTotal) * db.otherExpensesValue;
          };
          //// Volume
        } else if (parameter_prorate_expenses == 3) { // Volume
          if (db.item[i].dimensionIn) {
            db.item[i].otherExpensesItem = ((volume * db.item[i].quantityIt) / db.totalVolume) * db.otherExpensesValue;
          };
        };
        // console.log("db.item[i].otherExpensesItem = " + i + ' ' + db.item[i].otherExpensesItem);
        if (!db.item[i].otherExpensesItem) db.item[i].otherExpensesItem = 0;

      };
    };
  };
};







/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  MODULE Delivery /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  TOTAL GRID /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function calculateDeliveryGrid(db, locale) {
  // console.log("calculateTotal -> item =" + JSON.stringify(item));
  // console.log("locale =" + JSON.stringify(locale));

  let rowTotal = { // keep fields like a card: {
    itemId: null,
    invoiceId: null,
    devolutionId: null,
    //
    totalQuantity: 0,
    totalSale: 0,
    weightLiquid: 0,
    weightGross: 0,
    volume: 0,
    itemTeamValue: 0,
    quantityDelivered: 0,
    //
    //
    update: false,
    exclude: false,
    dateUpdateIt: null,

  };
  //
  let quantityTotal = 0;
  let totalSale = 0;
  let freightValue = 0;
  let weightLiquid = 0;
  let weightGross = 0;
  let volume = 0;
  let itemTeamValue = 0;
  let quantityDelivered = 0;

  //
  if (db.item.length > 0) {
    for (let i = 0; i < db.item.length; i++) {
      // console.log("[i]            = " + i);
      // console.log("[i].totalQuantity = " + db.item[i].totalQuantity);
      if (db.item[i].itemId && !db.item[i].exclude) {
        quantityTotal =
          quantityTotal + Number(db.item[i].totalQuantity);
        totalSale =
          totalSale + (Number(db.item[i].totalSale));
        freightValue =
          freightValue + (Number(db.item[i].freightValue));
        weightLiquid =
          weightLiquid + Number(db.item[i].weightLiquid);
        weightGross =
          weightGross + Number(db.item[i].weightGross);
        volume =
          volume + Number(db.item[i].volume);
        itemTeamValue =
          itemTeamValue + Number(db.item[i].itemTeamValue);


        // console.log("db.item[i].quantityDelivered = " + db.item[i].quantityDelivered);
        if (db.item[i].quantityDelivered) {
          quantityDelivered =
            quantityDelivered + Number(db.item[i].quantityDelivered);
        };
      };
      // console.log("quantityTotal = " + quantityTotal);
    };
    // rowTotal
    rowTotal.totalQuantity = quantityTotal;
    rowTotal.totalSale = totalSale;
    rowTotal.freightValue = freightValue;


    if (weightLiquid > 0)
      rowTotal.weightLiquid = new Intl.NumberFormat(locale.companyLocale, {}).format(weightLiquid) + ' ' + locale.weightIn;
    if (weightGross > 0)
      rowTotal.weightGross = new Intl.NumberFormat(locale.companyLocale, {}).format(weightGross) + ' ' + locale.weightIn;
    if (volume > 0)
      rowTotal.volume = new Intl.NumberFormat(locale.companyLocale, {}).format(volume) + ' ' + locale.dimensionIn + "³";
    if (itemTeamValue > 0)
      rowTotal.itemTeamValue = itemTeamValue;

    if (quantityDelivered > 0)
      rowTotal.quantityDelivered = quantityDelivered;

    // console.log("rowTotal.quantityDelivered = " + rowTotal.quantityDelivered);
    // console.log("rowTotal.totalQuantity     = " + rowTotal.totalQuantity);


    // dateIssue   => used in layout --> e-mail
    sessionStorage.dateIssue = db.dateIssueLocale || db.dateIssue;


  }
  return rowTotal;
};










///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};
// https://qastack.com.br/programming/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
///////////////////////////////////////
//   toDataURL(src, callback, outputFormat) {
//     var img = new Image();
//     img.crossOrigin = "Anonymous";
//     img.onload = function () {
//       var canvas = document.createElement("CANVAS");
//       var ctx = canvas.getContext("2d");
//       var dataURL;
//       canvas.height = this.naturalHeight;
//       canvas.width = this.naturalWidth;
//       ctx.drawImage(this, 0, 0);
//       dataURL = canvas.toDataURL(outputFormat);
//       callback(dataURL);
//     };
//     img.src = src;
//     if (img.complete || img.complete === undefined) {
//       img.src =
//         "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
//       img.src = src;
//     }
//   },

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  Session Storage  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////
export function clearSessionStorage() {

  sessionStorage.removeItem("newPosting");
  sessionStorage.removeItem("conciliationTemp");
  sessionStorage.OfxFileName = "";

  sessionStorage.removeItem("paymentMethod");
  sessionStorage.accountId = "";
  sessionStorage.removeItem("accountTemp");
  sessionStorage.payableId = "";
  sessionStorage.removeItem("payableTemp");
  sessionStorage.receivableId = "";
  sessionStorage.removeItem("receivableTemp");
  sessionStorage.cashierId = "";
  sessionStorage.removeItem("cashierTemp");

  sessionStorage.removeItem("sellerTempNew");
  sessionStorage.removeItem("teamTempNew");

  //
  sessionStorage.removeItem("consumerTemp");
  sessionStorage.dateSincApp = null;
  sessionStorage.pageId = "";
  // sessionStorage.module = "";
  sessionStorage.typeDelivery = "";
  sessionStorage.pageIdDelivery = "";
  sessionStorage.entityIdDelivery = "";

  sessionStorage.sourceModule = "";
  sessionStorage.mainPage = "";
  sessionStorage.sourcePage = "";
  sessionStorage.pageOrigin = "";
  sessionStorage.menuCounter = 0;
  sessionStorage.menuSum = 0;
  sessionStorage.menuTitle = "";


  sessionStorage.contactId = "";
  sessionStorage.profile = "";
  sessionStorage.routerOption = "";
  sessionStorage.entryChannel = "";

  sessionStorage.supplierId = "";
  sessionStorage.employeeId = "";

  sessionStorage.customerId = "";
  sessionStorage.customerName = "";
  sessionStorage.entityName = "";

  // Entity
  sessionStorage.entity = "";
  sessionStorage.entitySearch = "";
  sessionStorage.entityId = "";
  sessionStorage.customerName = "";
  sessionStorage.supplierName = "";
  sessionStorage.employeeName = "";
  sessionStorage.buyerName = "";
  sessionStorage.dateUpdateEntity = "";

  sessionStorage.removeItem("apiGeocoder");

  sessionStorage.sellerId = "";
  sessionStorage.contactSellerId = 0;

  sessionStorage.teamId = "";
  sessionStorage.contactTeamId = 0;
  sessionStorage.notCompletePersonalData = "";

  // contact
  sessionStorage.contactName = ""; // used in layout --> e-mail
  sessionStorage.contactEmail = "";

  sessionStorage.removeItem("importRecordsCompanyTemp");
  sessionStorage.removeItem("importRecordsStockTemp");

  //
  sessionStorage.removeItem("supplierTempNew");
  sessionStorage.removeItem("transporterTempNew");
  sessionStorage.removeItem("customerTempNew");
  //
  sessionStorage.removeItem("productTempNew");
  sessionStorage.removeItem("serviceTempNew");
  sessionStorage.removeItem("employeeTempNew");
  sessionStorage.removeItem("buyerTempNew");
  // User
  sessionStorage.removeItem("userContact");
  sessionStorage.accessId = "";

  // report -> passed by pages
  sessionStorage.reportId = "";
  sessionStorage.reportModelId = 0;

  // layout -> passed by pages
  sessionStorage.layoutId = "";
  sessionStorage.layoutModelId = 0;

  // stock
  sessionStorage.productId = "";
  sessionStorage.productDescription = "";
  sessionStorage.removeItem("productPriceSale");
  sessionStorage.removeItem("productPriceOffer");


  //
  sessionStorage.serviceId = "";
  sessionStorage.serviceDescription = "";


  sessionStorage.kitId = "";
  sessionStorage.kitName = "";
  // purchase

  sessionStorage.solicitId = "";
  sessionStorage.quotationBuyerName = "";

  sessionStorage.quotationId = "";
  sessionStorage.solicitEmployeeName = "";
  sessionStorage.orderId = "";
  sessionStorage.orderSupplierName = "";

  sessionStorage.receiveId = "";
  sessionStorage.receiveSupplierName = "";

  sessionStorage.backId = "";
  sessionStorage.backSupplierName = "";

  // commercial
  sessionStorage.addressDeliveryId = "";
  sessionStorage.deliveryType = "";

  sessionStorage.quoteId = "";
  sessionStorage.quoteCustomerrName = "";

  sessionStorage.saleId = "";
  sessionStorage.saleCustomerName = "";
  sessionStorage.tableId = "";
  sessionStorage.capacity = "";
  sessionStorage.commandId = "";


  sessionStorage.invoiceId = "";
  sessionStorage.invoiceCustomerName = "";

  sessionStorage.devolutionId = "";
  sessionStorage.devolutionCustomerName = "";

  sessionStorage.removeItem("sellerListLast");
  sessionStorage.removeItem("teamListLast");

  // delivery

  sessionStorage.deliveryId = "";
  sessionStorage.deliverySupplierName = "";
  sessionStorage.removeItem("Carrier DeliveryTemp");
  sessionStorage.removeItem("Own DeliveryTemp");
};




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///  REPORT CSV  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function createCSV(reportId, fields, table, modelTitle, locale, t, Parser, accounts) {
  // console.log("tableData = ", JSON.stringify(table));
  // console.log("reportId = ", reportId);

  convertDateAndLocale(reportId, table, locale, t, accounts)

  try {
    const parser = new Parser({ fields, delimiter: ";" });
    // const csv = parser.parse(this.table);
    const csv = parser.parse(table);
    // console.log(csv);

    const blob = new Blob(["\uFEFF" + csv], {
      type: "text/csv; charset=utf-18",
    });
    const e = document.createEvent("MouseEvents"),
      a = document.createElement("a");
    var csvName = modelTitle.trim().replace(/ /g, "_") + ".csv";
    csvName = csvName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // remove accents
    a.download = csvName;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
    e.initEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  } catch (err) {
    console.error(err);
  };
};




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// REPORT PDF //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function createReportPDF(reportId, table, modelTitle, headerPDF, rowTotal, headerReport, printPDF, tradingName, locale, t, accounts) {
  // //
  var pdfName = modelTitle.trim().replace(/ /g, "_");
  pdfName = pdfName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // remove accents

  // console.log("headerPDF " + JSON.stringify(headerPDF));
  // console.log("reportId = ", reportId);
  convertDateAndLocale(reportId, table, locale, t, accounts)

  let width = JSON.stringify(headerPDF).length;
  width = (Number(width) * 0.5).toFixed(0);
  // console.log("width " + width);

  // big  columns -> so as not to break lines /////////////////////////////////////////////
  let big = JSON.stringify(headerPDF);
  if (big.includes("company_name")) width = Number(width) + 70;
  if (big.includes("trading_name")) width = Number(width) + 70;
  if (big.includes("full_name")) width = Number(width) + 70;
  if (big.includes("address")) width = Number(width) + 70;
  // product & service
  if (big.includes("description")) width = Number(width) + 70;
  if (big.includes("_type")) width = Number(width) + 50;
  if (big.includes("supplier_id")) width = Number(width) + 70;

  if (big.includes("account_code")) width = Number(width) + 50;
  if (big.includes("bearer_name")) width = Number(width) + 70;

  // alert("Largura = " + width);

  // var doc = new jsPDF("p", "mm", [297, 210]); // "p" -> landscape ou portrait ; mm -> milimiter  ;  Document of 210mm wide and 297mm high
  if (width < 200) width = 200;
  if (width < 410) {
    var doc = new jsPDF("p", "mm", [297, width], true); // portrait
  } else {
    var doc = new jsPDF("l", "mm", [width, 297], true); // landscape
  }
  doc.setFont("Roboto-Regular");
  doc.setFontSize(10);

  // AUTOTABLE ////////////////////////////////////////////////////////////////////////////////////////
  // columns
  var columns = headerPDF;
  // console.log("headerPDF " + JSON.stringify(headerPDF));
  // pdf not support emoji
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].title == "📸") {
      columns[i].title = "";
    }
  }
  // rows
  let rows = table;
  if (reportId != "kit") {
    rows.push(rowTotal);
  }

  autoTable(doc, {
    // options ///////////////////////////////////////////////////
    theme: "striped",
    // columnWidth: "wrap",
    // styles: { overflow: "linebreak", overflowColumns: false },
    // margin: 10,
    rowPageBreak: "avoid",
    //
    columnStyles: {
      date_issue: { halign: "center" },
      //
      solicit_id: { halign: "right" },
      quotation_id: { halign: "right" },
      order_id: { halign: "right" },
      receive_id: { halign: "right" },
      back_id: { halign: "right" },
      //
      quote_id: { halign: "right" },
      sale_id: { halign: "right" },
      invoice_id: { halign: "right" },
      devolution_id: { halign: "right" },
      entity_id: { halign: "right" },
      seller_id: { halign: "right" },
      manager_id: { halign: "right" },
      team_id: { halign: "right" },
      //
      document_id: { halign: "right" },
      item_id: { halign: "center" },
      product_id: { halign: "center" },
      service_id: { halign: "center" },
      kit_id: { halign: "center" },
      quantity_it: { halign: "right" },
      price_cost: { halign: "right" },
      price_sale: { halign: "right" },
      total_cost: { halign: "right" },
      average_cost: { halign: "right" },
      average_cost_total: { halign: "right" },
      total_sale: { halign: "right" },
      price_sale_kit: { halign: "right" },
      total_sale_kit: { halign: "right" },
      current_it: { halign: "right" },
      price_cost_it: { halign: "right" },
      total_cost_it: { halign: "right" },
      average_cost_it: { halign: "right" },
      total_average_cost_it: { halign: "right" },
      measure_su: { halign: "center" },
      measure_cl: { halign: "center" },
      price_cost_current: { halign: "right" },
      price_sale_current: { halign: "right" },
      total_value: { halign: "right" },
      seller_commission: { halign: "right" },
      percent_seller: { halign: "right" },
      item_commission_value: { halign: "right" },
      price_cost_last: { halign: "right" },
      average_cost_sum: { halign: "right" },
      margin: { halign: "right" },
      margin_average_cost: { halign: "right" },

      // module financial
      expected_value: { halign: "right" },
      realized_value: { halign: "right" },
      balance: { halign: "right" },
      value_balance: { halign: "right" },
      installment_number: { halign: "center" },
      TRNAMT: { halign: "right" },

      // module Delivery
      basic_value: { halign: "right" },
      total_quantity: { halign: "right" },
      weight_liquid: { halign: "right" },
      weight_gross: { halign: "right" },
      volume: { halign: "right" },
      item_team_value: { halign: "right" },
      quantity_delivered: { halign: "right" },

      date_delivered: { halign: "center" },
      time_delivered: { halign: "center" },

      rating: { halign: "center" },
    },
    didParseCell: function (data) {
      // fontStyle = 'bold'
      if (data.row.index === data.table.body.length - 1) {
        data.cell.styles.fontStyle = "bold";
      }
      //
      if (data.section === "body") {
        if (
          data.column.dataKey == "product_id" ||
          data.column.dataKey == "service_id"
        ) {
          if (!data.cell.raw) data.cell.text = "";
        }
      }
      //
    },
    /////////////
    didDrawCell: function (data) {
      // console.log("data = " + JSON.stringify(data));
      // srcX
      if (
        data.column.dataKey == "srcX" &&
        data.cell.raw &&
        data.cell.section === "body"
      ) {
        let base64 = rows[data.row.index].srcBase64;
        if (base64) {
          doc.addImage(
            base64,
            "JPEG",
            data.cell.x,
            data.cell.y,
            data.cell.height,
            data.cell.height
          );
        }
      }
    },

    // body ////////////
    body: rows,
    // columns ////////
    columns: columns,
  });

  // HEADER & FOOTER /////////////////////////////////////////////////////////////////////////////////////////////////
  // Get the number of pages
  const pageTotal = doc.internal.getNumberOfPages();
  // doc.setFontSize(10); // optional
  for (var i = 1; i <= pageTotal; i++) {
    //
    doc.setPage(i); // Go to page i
    doc.setLineWidth(0.3); // line thickness
    //////////////////////////////////////////////////////////////////
    // HEADER ///////////////////////////////////////////////////////

    let date = new Date().toLocaleString().substring(0, 17);
    date = date.replace(",", "");

    let text = tradingName + " - " + modelTitle.trim() + " " + headerReport.trim();
    // console.log("text.length = " + text.length);
    if (text.length > 110) {
      doc.setFontSize(9);
    } else if (text.length > 90) {
      doc.setFontSize(10);
    } else if (text.length > 70) {
      doc.setFontSize(11);
    } else {
      doc.setFontSize(12);
    }

    doc.text(text, 14, 9); // left, top -> x, y
    doc.setFontSize(10);
    doc.text(date, doc.internal.pageSize.width - 44, 9);
    doc.line(14, 11, doc.internal.pageSize.width - 15, 11);

    ////////////////////////////////////////////////////////////////
    // FOOTER///////// /////////////////////////////////////////////
    // line
    doc.setLineWidth(0.2);
    doc.setFontSize(9);
    doc.text("blue21.com.br", 5, doc.internal.pageSize.height - 7); //key is the interal pageSize function
    //
    doc.setDrawColor(69, 90, 100); // blue-grey-darken-2
    doc.line(
      5,
      doc.internal.pageSize.height - 6,
      doc.internal.pageSize.width - 21,
      doc.internal.pageSize.height - 6
    );
    // pag
    var str = "Pag: " + String(i) + " / " + pageTotal;
    doc.text(
      str,
      doc.internal.pageSize.width - 19,
      doc.internal.pageSize.height - 6
    ); //key is the interal pageSize function
  }
  ///////////////////////////////////////////////////////
  try {
    // doc.page = 1;
    if (!printPDF) {
      doc.save(pdfName + ".pdf");
      // print
    } else {
      doc.setProperties({ title: pdfName });
      window.open(doc.output("bloburl"), "_blank");
    }
    //
    rows.pop();
    //
  } catch (e) {
    rows.pop()
    console.info("createReportPDF Err " + e);
  } finally {
    //
  }
};

function getAccountCode(accountId, accounts) {
  // console.log("2 accountId = " + accountId);
  if (Number(accountId) < 1) return null;
  let index = accounts.findIndex((x) => x.accountId == accountId);
  // console.log("3 index    = " + index);
  if (index < 0) return null;
  return accounts[index].accountCode;
};


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function convertDateAndLocale(reportId, table, locale, t, accounts) {
  //  for only createCSV() and createReportPDF()

  // not work for reportId == "consumer_manufacture"
  if (reportId == "consumer_manufacture") return;

  for (let i = 0; i < table.length; i++) {
    // date /////////////////////////////////////////////////////////////////////////////////////////
    if (reportId == "cashflow" || reportId == "conciliation") {
      if (table[i].due_date) {
        table[i].due_date = formatDate(table[i].due_date, locale).toString();
      }
      if (table[i].posting_date) {
        table[i].posting_date = formatDate(table[i].posting_date, locale).toString();
      }
      if (table[i].discharge_date) {
        table[i].discharge_date = formatDate(table[i].discharge_date, locale).toString();
      }
      if (table[i].date_debit_credit) {
        table[i].date_debit_credit = formatDate(table[i].date_debit_credit, locale).toString();
      }
      //
      if (table[i].DTPOSTED) {
        table[i].DTPOSTED = formatDateSplit(table[i].DTPOSTED, locale).toString();
      }

    }

    if (reportId == "conciliation") {
      if (table[i].expected_value) {
        table[i].expected_value = formatValueConciliation(table[i].expected_value, locale);
      }
      if (table[i].realized_value) {
        table[i].realized_value = formatValueConciliation(table[i].realized_value, locale);
      }
    }

    ///// Account Code ////////////////////////////////////////////////////////////////////////////////
    if (table[i].account_id || table[i].account_id == 0) {
      table[i].account_id = getAccountCode(table[i].account_id, accounts)
    }

    ///// $t /////////////////////////////////////////////////////////////////////////////////////////
    // sale_channel 
    if (table[i].sale_channel) {
      table[i].sale_channel = t("common." + table[i].sale_channel);
    }
    // delivery_type
    if (table[i].delivery_type) {
      table[i].delivery_type = t("common." + table[i].delivery_type);
    }
    //
    // status_quotation
    if (table[i].status_quotation) {
      table[i].status_quotation = t("common." + table[i].status_quotation);
    }
    // status_order
    if (table[i].status_order) {
      table[i].status_order = t("common." + table[i].status_order);
    }
    // status_receive
    if (table[i].status_receive) {
      table[i].status_receive = t("common." + table[i].status_receive);
    }
    //
    // status_quote
    if (table[i].status_quote) {
      table[i].status_quote = t("common." + table[i].status_quote);
    }
    // status_sale
    if (table[i].status_sale) {
      table[i].status_sale = t("common." + table[i].status_sale);
    }
    // status_invoice
    if (table[i].status_invoice) {
      table[i].status_invoice = t("common." + table[i].status_invoice);
    }
    // status_delivered
    if (table[i].status_delivered) {
      table[i].status_delivered = t("delivery." + table[i].status_delivered);
    }

    // module financial
    if (table[i].payment_status) {
      table[i].payment_status = t('common.' + table[i].payment_status)
    };
    if (table[i].bearer) {
      table[i].bearer = t('common.' + table[i].bearer)
    };
    if (table[i].document_type) {
      table[i].document_type = t('common.' + table[i].document_type)
    };
    if (table[i].type_operation) {
      table[i].type_operation = t('common.' + table[i].type_operation)
    };
    if (table[i].type_account) {
      table[i].type_account = t('common.' + table[i].type_account)
    };
    if (table[i].type_cashier) {
      table[i].type_cashier = t('common.' + table[i].type_cashier)
    };

    // others /////////////////////////////////////////////////////////////////////////////////////////
    if (table[i].installment_number) {
      table[i].installment_number = " " + table[i].installment_number + " "
    };

  }
  return table;
};


